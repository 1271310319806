import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { StripeTransactionService } from 'app/main/shared/stripeTransaction.service';
import { Dashboarddata } from 'app/main/shared/dashaboardata';
import { formatter } from 'app/main/shared/formatter';
import { StripeTransaction } from 'app/main/shared/stripeTransaction.model';
import { StripePayoutService } from 'app/main/shared/stripePayout.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatatablesService implements Resolve<any> {
  rows: any;
  idOrg: string;
  onDatatablessChanged: BehaviorSubject<any>;
  public currentListoObs: Observable<any>;
  public currentUser: User;

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  idOrganization: String;
  modelDashboard: any;

  loading: String = "none";
  averageTransaction: number = 0;
  nbDonorWhitoutEmail: number = 0;
  nbDonationWithoutEmail: number = 0;
  nbDonorWhithEmail: number = 0;
  nbTransactionWhithEmail: number = 0;
  totalAmountTransactionWithEmail: number = 0;
  totalAmountTransactionWithoutEmail: number = 0;


  sumPaid: any = 0;
  sumPending: any = 0;
  sumInTransit: any = 0;
  sumCanceled: any = 0;
  sumFailed: any = 0;
  errorServer;

  donorsArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  unKnowDonor: any = {
    _id: '',
    totalTransaction: 0,
    amounttotal: 0
  };

  currentTranction: StripeTransaction;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
    private stripeTransactionService: StripeTransactionService,
    private stripePayoutService: StripePayoutService
  ) {

    this.formartter = new formatter();
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentListoObs = this.onDatatablessChanged.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }



  /**
   * Get rows
   */
  getDataTableRows2(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/api/stripe/transaction/all/organization/${this.currentUser.idOrg}`).subscribe((response: any) => {
        //this.rows = InvoiceFakeData.invoices;
        this.rows = response['transactions'];
        this.onDatatablessChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }


  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise((resolve, reject) => {
      this.stripePayoutService.getStripePayoutByOrganization(this.currentUser.idOrg).subscribe((response: any) => {
        //this.rows = InvoiceFakeData.invoices;
        this.rows = response['stripePayout'];
        this.sortPaiement(this.rows);

        this.onDatatablessChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }

  refreshData() {
    this.getDataTableRows();

  }
  sortPaiement(stripePayoutTransactions) {
    if (stripePayoutTransactions !== undefined && stripePayoutTransactions !== null) {
      if (Array.isArray(stripePayoutTransactions)) {
        stripePayoutTransactions.reverse();
        for (let i = 0; i < stripePayoutTransactions.length; i++) {

          stripePayoutTransactions[i].dateCreate = new Date(stripePayoutTransactions[i].infoObject.created * 1000);
          stripePayoutTransactions[i].dateArrival = new Date(stripePayoutTransactions[i].infoObject.arrival_date * 1000);

          const stripePayoutTransaction = stripePayoutTransactions[i].status;

          switch (stripePayoutTransaction) {
            case 'paid':
              this.sumPaid += Number(stripePayoutTransactions[i].amount);
              stripePayoutTransactions[i].statusTxt = "Payé";
              break;
            case 'pending':
              this.sumPending += Number(stripePayoutTransactions[i].amount);
              stripePayoutTransactions[i].statusTxt = "En attente";
              break;
            case 'in_transit':
              this.sumInTransit += Number(stripePayoutTransactions[i].amount);
              stripePayoutTransactions[i].statusTxt = "En transit";
              break;
            case 'canceled':
              this.sumCanceled += Number(stripePayoutTransactions[i].amount);
              stripePayoutTransactions[i].statusTxt = "Annulé";
              break;
            case 'failed':
              this.sumFailed += Number(stripePayoutTransactions[i].amount);
              stripePayoutTransactions[i].statusTxt = "Échoué";
              break;
          }
        }
      }
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
