import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { CurrencyFormat, CurrencyFormatDecimal, CurrencyFormatWithoutDecimal, NumberFormatDecimalFr, NumberFormatFr, CurrencyFormatWithoutDecimalCompress } from '../../app/main/shared/custompipe.pipe';
@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,CurrencyFormat, CurrencyFormatDecimal, CurrencyFormatWithoutDecimal, NumberFormatDecimalFr, NumberFormatFr, CurrencyFormatWithoutDecimalCompress],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,CurrencyFormat, CurrencyFormatDecimal, CurrencyFormatWithoutDecimal, NumberFormatDecimalFr, NumberFormatFr, CurrencyFormatWithoutDecimalCompress]
})
export class CorePipesModule {}
