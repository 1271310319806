import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable ,throwError} from 'rxjs';
import { catchError, finalize, mergeMap, retryWhen } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { ErrorSeverity,BackendError } from '../models/error-message';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService,private _router: Router) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiUrl2 = request.url.startsWith('/api');
    const isApiUrl3 = request.url.startsWith(environment.apiBaseUrl4);
    if (isLoggedIn && (isApiUrl || isApiUrl2 || isApiUrl3)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof ErrorEvent) {
          console.log('this is an error in the code');
        
        } else {
          console.log('this is an error return by the server');
          if(err.error !== undefined && err.error != null){
            if(err.error.auth !== undefined && err.error.auth != null){
              if(err.error.auth == false){
                //this._router.navigate(['/pages/authentication/login-v2']);
                this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: this._router.url } });

              }
            }
          }
        }
        return throwError(err);
      }),
    );
  }


  private handleUnknownError(): BackendError {
    // this is not from backend. Format our own message.
    return {
      message: 'Unknown error!',
      severity: ErrorSeverity.FATAL,
      code: 'UNKNOWN_ERROR',
    };
  }

  private handleBackendError(error: BackendError, err): BackendError {
    // Backend returned error, format it here
    return {
      title: err.error?.title || 'Default title',
      message: err.error && err.error.message ? err.error.message : err.error ? err.error : err.message,
      severity: ErrorSeverity.ERROR,
      code: err.error?.identifierCode ? err.error.identifierCode : 'BACKEND_ERROR',
    };
  }
}
