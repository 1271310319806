export class CardAnalyticsData {
    public static data = {
      supportTracker: {
        series: [83],
        analyticsData: {
          tickets: 163,
          newTickets: 29,
          openTickets: 63,
          responseTime: '1d'
        }
      },
      avgSessions: {
        series: [
          {
            name: 'Sessions',
            data: [75, 125, 225, 175, 125, 75, 25]
          }
        ],
        analyticsData: {
          avgSessions: '2.7K',
          vsLastSevenDays: '+5.2%',
          goal: '$100000',
          goalProgressbar: 50,
          retention: '90%',
          retentionProgressbar: 60,
          users: '100K',
          usersProgressbar: 70,
          duration: '1yr',
          durationProgressbar: 90
        }
      },
      revenueReport: {
        earningExpenseChart: {
          series: [
            {
              name: 'Earning',
              data: [95, 177, 284, 256, 105, 63, 168, 218, 72]
            },
            {
              name: 'Expense',
              data: [-145, -80, -60, -180, -100, -60, -85, -75, -100]
            }
          ]
        },
        budgetChart: {
          series: [
            {
              data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62]
            },
            {
              data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27]
            }
          ]
        },
        analyticsData: {
          currentBudget: '$25,852',
          totalBudget: '56,800'
        }
      },
      goalOverview: {
        series: [83],
        analyticsData: {
          completed: '786,617',
          inProgress: '13,561'
        }
      },
      revenue: {
        series: [
          {
            name: 'This Month',
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600]
          },
          {
            name: 'Last Month',
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000]
          }
        ],
        analyticsData: {
          thisMonth: '86,589',
          lastMonth: '73,683'
        }
      },
      salesLastSixMonths: {
        series: [
          {
            name: 'Sales',
            data: [90, 50, 86, 40, 100, 20]
          },
          {
            name: 'Visit',
            data: [70, 75, 70, 76, 20, 85]
          }
        ]
      },
      sales: {
        series: [
          {
            name: 'Sales',
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295]
          }
        ],
        analyticsData: {
          totalSales: '12.84k'
        }
      },
      productOrders: {
        series: [70, 52, 26],
        analyticsData: {
          finished: 23043,
          pending: 14658,
          rejected: 4758
        }
      },
      sessionsByDevice: {
        series: [58.6, 34.9, 6.5],
        analyticsData: {
          desktop: {
            desktopPercentage: '58.6%',
            changePercentage: '2%'
          },
          mobile: {
            mobilePercentage: '34.9%',
            changePercentage: '8%'
          },
          tablet: {
            tabletPercentage: '6.5%',
            changePercentage: '-5%'
          }
        }
      },
      customers: {
        series: [690, 258, 149],
        analyticsData: {
          new: 690,
          returning: 258,
          referrals: 149
        }
      },
      monthlyTransaction : {
        transactions: [
          {
              x:0,
              label: '01/2021',
              date: '2021-01-31T00:00:00.000Z',
              y: 5000,
              nbT: 0
          },
          {
              x:1,
              label: '02/2021',
              date: '2021-02-28T00:00:00.000Z',
              y: 20,
              nbT: 0
          },
          {
              x:2,
              label: '03/2021',
              date: '2021-03-30T23:00:00.000Z',
              y: 568,
              nbT: 0
          },
          {
              x:3,
              label: '04/2021',
              date: '2021-04-29T23:00:00.000Z',
              y: 16586,
              nbT: 0
          },
          {
              x:4,
              label: '05/2021',
              date: '2021-05-30T23:00:00.000Z',
              y: 25,
              nbT: 0
          },
          {
              x:5,
              label: '06/2021',
              date: '2021-06-29T23:00:00.000Z',
              y: 45,
              nbT: 0
          },
          {
              x:6,
              label: '07/2021',
              date: '2021-07-30T23:00:00.000Z',
              y: 65,
              nbT: 0
          },
          {
              x:7,
              label: '08/2021',
              date: '2021-08-30T23:00:00.000Z',
              y: 1250,
              nbT: 0
          },
          {
              x:8,
              label: '09/2021',
              date: '2021-09-29T23:00:00.000Z',
              y: 0,
              nbT: 0
          },
          {
              x:9,
              label: '10/2021',
              date: '2021-10-30T23:00:00.000Z',
              y: 75,
              nbT: 0
          },
          {
              x:10,
              label: '11/2021',
              date: '2021-11-30T00:00:00.000Z',
              y: 5896,
              nbT: 0
          },
          {
              x:11,
              label: '12/2021',
              date: '2021-12-31T00:00:00.000Z',
              y: 456,
              nbT: 0
          }
      ]
      },
      dailyCampaign : {

        campaign: [
          {
              'type': 'spline',
              'name': 'Frais mosquée',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 5,
                      'nbT': 1
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 5,
                      'nbT': 1
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 45,
                      'nbT': 3
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 40,
                      'nbT': 1
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 75,
                      'nbT': 3
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 20,
                      'nbT': 2
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'Paiements',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 45,
                      'nbT': 3
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 20,
                      'nbT': 2
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 20,
                      'nbT': 1
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'IFTAR',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'FIDYA ET KAFFARA',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'ZAKAT EL FITR',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'ZAKAT EL-MAAL',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 5,
                      'nbT': 1
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 20,
                      'nbT': 1
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 5,
                      'nbT': 1
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'Intérêts bancaires',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  }
              ]
          },
          {
              'type': 'spline',
              'name': 'Education et enseignement',
              'yValueFormatString': '#0.## €',
              'showInLegend': true,
              'dataPoints': [
                  {
                      'x': 0,
                      'label': '19/11/2021',
                      'date': '2021-11-19T00:00:00.000Z',
                      'y': 50,
                      'nbT': 1
                  },
                  {
                      'x': 1,
                      'label': '20/11/2021',
                      'date': '2021-11-20T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 2,
                      'label': '21/11/2021',
                      'date': '2021-11-21T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 3,
                      'label': '22/11/2021',
                      'date': '2021-11-22T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 4,
                      'label': '23/11/2021',
                      'date': '2021-11-23T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 5,
                      'label': '24/11/2021',
                      'date': '2021-11-24T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 6,
                      'label': '25/11/2021',
                      'date': '2021-11-25T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 7,
                      'label': '26/11/2021',
                      'date': '2021-11-26T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 8,
                      'label': '27/11/2021',
                      'date': '2021-11-27T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 9,
                      'label': '28/11/2021',
                      'date': '2021-11-28T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 10,
                      'label': '29/11/2021',
                      'date': '2021-11-29T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 11,
                      'label': '30/11/2021',
                      'date': '2021-11-30T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 12,
                      'label': '1/12/2021',
                      'date': '2021-12-01T00:00:00.000Z',
                      'y': 0,
                      'nbT': 0
                  },
                  {
                      'x': 13,
                      'label': '2/12/2021',
                      'date': '2021-12-02T00:00:00.000Z',
                      'y': 10,
                      'nbT': 1
                  },
                  {
                      'x': 14,
                      'label': '3/12/2021',
                      'date': '2021-12-03T00:00:00.000Z',
                      'y': 110,
                      'nbT': 2
                  },
                  {
                      'x': 15,
                      'label': '4/12/2021',
                      'date': '2021-12-04T00:00:00.000Z',
                      'y': 70,
                      'nbT': 4
                  }
              ]
          }
    ]

      },

      earnings: {
        series: [53, 16, 31]
      }
    };
  }
  