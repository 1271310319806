import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { StripeTransactionService } from 'app/main/shared/stripeTransaction.service';
import { CampaignService } from 'app/main/shared/campaign.service';
import { distinctUntilChanged } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class DatatablesService implements Resolve<any> {
  rows: any;
  rows_campaign: any;
  idOrg: string;
  onDatatablessChanged: BehaviorSubject<any>;
  public onUserListChanged: BehaviorSubject<any>;
  public currentUser: User;

  public currentListoObs: Observable<any>;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
    private stripeTransactionService: StripeTransactionService,
    private campaignService: CampaignService
  ) {
    // Set the defaults
    this.onUserListChanged = new BehaviorSubject({});
    this.onDatatablessChanged = new BehaviorSubject({});
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentListoObs = this.onDatatablessChanged.asObservable().pipe(distinctUntilChanged());

  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let currentId = route.paramMap.get('id');
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows(currentId), this.getDataCampaigns()]).then(() => {
        resolve();
      }, reject);
    });
  }

  refreshData() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getDataTableRows2(this.currentUser.idOrg);
  }


  formatList() {



  }


  /**
   * Get rows
   */
  getDataTableRows2(currentId: string): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/api/stripe/transaction/all/organization/${this.currentUser.idOrg}`).subscribe((response: any) => {
        //this.rows = InvoiceFakeData.invoices;
        this.rows = response['transactions'];
        this.onDatatablessChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }

  formatTransactions(tRows) {

    if (Array.isArray(tRows)) {
      tRows.forEach(element => {
        let data;
        element.campaigntitle = "";
        element.campaignId = "";
        element.enableReceipt = false;
        if (element.payment_method_details !== undefined && element.payment_method_details !== null) {
          if (element.payment_method_details.card !== undefined && element.payment_method_details.card !== null) {
            data = element.payment_method_details.card;
          }
          if (element.payment_method_details.card_present !== undefined && element.payment_method_details.card_present !== null) {
            data = element.payment_method_details.card_present;
          }
        }
        element.payment_method_details.info = data;


        if (Array.isArray(element.campaign) && element.campaign.length > 0) {
          if (element.campaign[0].title !== undefined && element.campaign[0].title !== null) {
            element.campaigntitle = element.campaign[0].title;
            element.campaignId = element.campaign[0].idCampaign;
          }

        }

        if (element.campaign !== undefined && element.campaign !== null) {
          if (element.campaign.receiptfiscal !== undefined && element.campaign.receiptfiscal !== null) {
            if (element.campaign.receiptfiscal.available !== undefined && element.campaign.receiptfiscal.available !== null) {
              element.enableReceipt = element.campaign.receiptfiscal.available;
            }
          }

        }


      });

    }

    return tRows;
  }

  /**
 * Get rows
 */
  getDataTableRows(currentId: string): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise((resolve, reject) => {
      this.stripeTransactionService.getStripeTransactionOrganisationByEmail(this.currentUser.idOrg, currentId).subscribe((response: any) => {
        //this.rows = InvoiceFakeData.invoices;
        this.rows = response['transactions'];
        this.rows = this.formatTransactions(this.rows);
        this.onDatatablessChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }

  calculAmount(listCampaign) {
    var amoutTotal = 0;
    listCampaign.forEach(element1 => {
      element1.amountTotal = 0;
      element1.feeTotal = 0;
      element1.netTotal = 0;
      element1.transacCampaing.forEach(element2 => {
        if (!isNaN(element2.amount))
          element1.amountTotal = element2.amount + element1.amountTotal;
        if (!isNaN(element2.fee))
          element1.feeTotal = element2.fee + element1.feeTotal;
        if (!isNaN(element2.net))
          element1.netTotal = element2.net + element1.netTotal;

      });
    });

    return listCampaign;
  }


  /**
 * Get rows
 */
  getDataCampaigns(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var params = { "filterState": 'all' };
    var param = {
      params: params
    }
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/api/campaign/organization/bisV2/${this.currentUser.idOrg}`, param).subscribe((response: any) => {
        // this.rows_campaign = this.calculAmount(response['campaigns']);
        this.rows_campaign = response['campaigns'];
        this.onUserListChanged.next(this.rows_campaign);
        resolve(this.rows_campaign);
      }, reject);
    });
  }



}
