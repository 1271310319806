import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from 'app/auth/models';
import { Campaign } from 'app/main/shared/campaign.model';
import { CampaignService } from 'app/main/shared/campaign.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CampaignListService implements Resolve<any> {
  public rows: any;
  public onUserListChanged: BehaviorSubject<any>;
  public currentUser: User;
  public currentCampaign: Observable<any>;
  public _onNewCampaignChanged: BehaviorSubject<Campaign>;
  public _onRefreshCampaignPanelChanged: BehaviorSubject<any>;
  public _onNewCampaignListChanged: BehaviorSubject<any>;
  public currentNewCampaign: Campaign;


  public _onEditCampaignChanged: BehaviorSubject<Campaign>;
  public currentEditCampaign: Campaign;
  public modeEdit: boolean;
  urlpath: string;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _CampaignService: CampaignService) {
    // Set the defaults
    this.onUserListChanged = new BehaviorSubject({});
    this.currentNewCampaign = new Campaign();
    this.currentNewCampaign.icon = "icon13";
    this._onNewCampaignChanged = new BehaviorSubject<Campaign>(this.currentNewCampaign);
    this._onRefreshCampaignPanelChanged = new BehaviorSubject<any>(null);
    this._onNewCampaignListChanged = new BehaviorSubject<any>([]);
    this.modeEdit = false;

    this.urlpath = "https://app.v2.e6kdonation.com/files/icones/";

    this.currentCampaign = this._onNewCampaignListChanged.asObservable().pipe(distinctUntilChanged());
  }

  onNewCampaignChanged(data: any) {
    this._onNewCampaignChanged.next(data);
  }

  setEditCampaign(oData: Campaign) {
    this.currentEditCampaign = new Campaign();
    this.currentEditCampaign = oData;
    this.modeEdit = true;
    this._onEditCampaignChanged = new BehaviorSubject<Campaign>(this.currentEditCampaign);
  }

  onEditCampaignChanged(data: any) {
    data.iconpath = this.urlpath + data.icon.toString() + ".png";
    this._onEditCampaignChanged.next(data);
  }
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    }).catch(function (error) {
      console.log(error);
    });
  }


  calculAmount(listCampaign) {
    var amoutTotal = 0;
    listCampaign.forEach(element1 => {
      element1.amountTotal = 0;
      element1.feeTotal = 0;
      element1.netTotal = 0;
      element1.transacCampaing.forEach(element2 => {
        if (!isNaN(element2.amount))
          element1.amountTotal = element2.amount + element1.amountTotal;
        if (!isNaN(element2.fee))
          element1.feeTotal = element2.fee + element1.feeTotal;
        if (!isNaN(element2.net))
          element1.netTotal = element2.net + element1.netTotal;

      });
    });

    return listCampaign;
  }


  refreshData() {

    this.getDataTableRows();

  }

  refreshListOrgPanelNewCampaign() {
    this._onRefreshCampaignPanelChanged.next(null);
  }


  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var params = { "filterState": 'all' };
    var param = {
      params: params
    }
    if (this.currentUser !== undefined && this.currentUser !== null) {
      if (this.currentUser.idOrg !== undefined && this.currentUser.idOrg !== null) {

        return new Promise((resolve, reject) => {
          this._httpClient.get(`${environment.apiUrl}/api/campaign/organization/bisV2/${this.currentUser.idOrg}`, param).subscribe((response: any) => {
            //this.rows = this.calculAmount(response['campaigns']);
            this.rows = response['campaigns'];
            this.onUserListChanged.next(this.rows);
            this._onNewCampaignListChanged.next(this.rows);
            resolve(this.rows);
          }, reject);
        });

      }
    }
  }
}
