import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { StripeTransactionService } from 'app/main/shared/stripeTransaction.service';
import { Dashboarddata } from 'app/main/shared/dashaboardata';
import { formatter } from 'app/main/shared/formatter';
import { StripeTransaction } from 'app/main/shared/stripeTransaction.model';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatatablesService implements Resolve<any> {
  rows: any;
  idOrg: string;
  onDatatablessChanged: BehaviorSubject<any>;
  public currentListoObs: Observable<any>;
  public currentUser: User;

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  idOrganization: String;
  modelDashboard: any;

  loading: String = "none";
  averageTransaction: number = 0;
  nbDonorWhitoutEmail: number = 0;
  nbDonationWithoutEmail: number = 0;
  nbDonorWhithEmail: number = 0;
  nbTransactionWhithEmail: number = 0;
  totalAmountTransactionWithEmail: number = 0;
  totalAmountTransactionWithoutEmail: number = 0;
  errorServer;

  donorsArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  unKnowDonor: any = {
    _id: '',
    totalTransaction: 0,
    amounttotal: 0
  };

  currentTranction: StripeTransaction;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
    private stripeTransactionService: StripeTransactionService
  ) {

    this.formartter = new formatter();
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentListoObs = this.onDatatablessChanged.asObservable().pipe(distinctUntilChanged());

  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  refreshData() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getDataTableRows();
  }

  /**
   * Get rows
   */
  getDataTableRows2(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null && this.currentUser.idOrg !== undefined && this.currentUser.idOrg !== null) {
      return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/api/stripe/transaction/all/organization/${this.currentUser.idOrg}`).subscribe((response: any) => {
          //this.rows = InvoiceFakeData.invoices;
          this.rows = response['transactions'];
          this.onDatatablessChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
      });
    }
  }


  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null && this.currentUser.idOrg !== undefined && this.currentUser.idOrg !== null) {
      return new Promise((resolve, reject) => {
        this.stripeTransactionService.getStripeEmailDonorByOrganisation(this.currentUser.idOrg).subscribe((response: any) => {
          //this.rows = InvoiceFakeData.invoices;
          this.rows = response['donors'];
          this.rows = this.findUnknowDonator(this.rows);
          this.onDatatablessChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
      });
    }
  }


  findUnknowDonator(donors) {

    const arrayDonator = [];
    this.unKnowDonor = {
      _id: 'null',
      totalTransaction: 0,
      amounttotal: 0
    };
    this.nbTransactionWhithEmail = 0;
    this.totalAmountTransactionWithoutEmail = 0;
    for (let i = 0; i < donors.length; i++) {
      const _id = donors[i]._id;
      donors[i].unKnowDonor = false;
      donors[i].emailTxt = _id;
      if (_id !== 'null' && _id !== null) {

        arrayDonator.push(donors[i]);
        this.nbTransactionWhithEmail = this.nbTransactionWhithEmail + donors[i].totalTransaction;
        this.totalAmountTransactionWithEmail = this.totalAmountTransactionWithEmail + donors[i].amounttotal;
      } else if (_id === 'null' || _id === null) {
        donors[i].unKnowDonor = true;
        donors[i].emailTxt = "sans email"
        this.unKnowDonor.totalTransaction = this.unKnowDonor.totalTransaction + donors[i].totalTransaction;
        this.totalAmountTransactionWithoutEmail = this.totalAmountTransactionWithoutEmail + donors[i].amounttotal;
        this.unKnowDonor.amounttotal = this.totalAmountTransactionWithoutEmail;
        this.unKnowDonor.unKnowDonor = true;
      }
    }


    arrayDonator.reverse();
    arrayDonator.sort(this.dynamicSort("emailTxt"));
    this.unKnowDonor['_id'] = "null";
    this.unKnowDonor['emailTxt'] = "sans email";
    arrayDonator.unshift(this.unKnowDonor);


    return arrayDonator;
  }
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
