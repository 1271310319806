export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Dashboard',
      SAMPLE: 'Sample',
      DASHBOARD: {
        BADGE: '2',
        COLLAPSIBLE: 'Dashboard',
        DAY: "Suivi du jour",
        MONTH: "Suivi du mois",
        YEAR: "Suivi année",
        ANALYTICS: "Analytics"
      },
      TRANSACTION: "Transaction",
      CAMPAIGN: "Campagne",
      DONATOR: "Donateur",
      PAYOUT: "Versement",
      KIOSK: "Kiosk",
      TRANSACTIONIMPORT: "Import Transaction",
      RECEIPT: "Reçu"
    }
  }
}
