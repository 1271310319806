// import { Kiosk } from './kiosk.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Kiosk } from './kiosk.model';
import { delay, map, tap } from 'rxjs/operators';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class KioskService {

  public items;
  onDatatablessChanged: BehaviorSubject<any>;
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) {
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  addKiosk(kioskValue) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/add', kioskValue);
  }

  getKioks() {
    return this.http.get(environment.apiBaseUrl + '/kiosk/all');
  }

  getKioskOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/kiosk/' + id);
  }

  update(kioskValue) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update', kioskValue);
  }

  getKioksById(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/' + id);
  }

  kioskByIdAndOrganisation(idO, idK) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/transaction/' + idO + '/' + idK);
  }

  deleteOrganisationKiosk(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/delete/organisation/' + id);
  }

  deleteKiosk(id) {
    return this.http.delete(environment.apiBaseUrl + '/kiosk/delete/' + id);
  }

  // getKioksByOrganisation(id) {
  //   return this.http.get(environment.apiBaseUrl + '/kiosk/organisation/' + id, this.noAuthHeader);
  // }

  getKiosksByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactionsv2/' + id);
  }

  setTemplateKiosk(id, data) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update/template/' + id, data);
  }

  setDisplayNameKiosk(id, data) {
    return this.http.post(environment.apiBaseUrl + '/kiosk/update/displayName/' + id, data);
  }
  getTemplateKiosk(id) {
    return this.http.get(environment.apiBaseUrl + '/template');
  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }
  /**
     * Get rows
     */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let term = "5f7f2d4e4ea74415cc30eee5";
      this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactions/' + term).subscribe((response: any) => {
        //this.rows = InvoiceFakeData.invoices;
        var listData = response['kiosks'];
        this.items = listData;
        this.onDatatablessChanged.next(this.items);
        resolve(this.items);
      }, reject);
    });
  }


  getKiosksBy(term: string = null): Observable<Kiosk[]> {

    if (this.items === undefined) {
      this.items = new Array();
    }


    // this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactions/' + term).subscribe((res: any) => {     
    //     console.log(res);
    //     var listData = res['kiosks'];
    //     this.items = listData;        
    // }
    // );

    return this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactions/' + term).pipe(
      map(
        (jsonArray: Object[]) => jsonArray.map(jsonItem => jsonItem['kiosks'])
      )
    );



    // return of(this.items  ).pipe(delay(50));  


  }

  setTemplateTileAmount(idKiosk, data) {
    return this.http.post(environment.apiBaseUrl + '/kioskv2/updatetile/' + idKiosk, data);
  }



}
