import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripePayoutService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  getStripePayoutByOrganization(id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('idOrga', true.toString());

    let param = {
      idOrga : true
    }

    return this.http.post(environment.apiBaseUrl + '/stripe/payout/transaction/organization/' + id, param);
  }

}
