<li ngbDropdown class="nav-item dropdown-cart mr-25">
  <a *ngIf="currentUser.selectOrg" class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown"><span
      [data-feather]="'filter'" [class]="'ficon'"></span><span
      class="badge badge-pill badge-primary badge-up cart-item-count">{{ cartListLength
      }}</span></a>




  <ul ngbDropdownMenu aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Mes Organisations</h4>
        <div class="badge badge-pill badge-light-primary">{{ cartListLength }} Organisations</div>
      </div>

    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <div class="media align-items-center">
        <div class="media-body">

          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio0" name="customRadio" class="custom-control-input"
              [(ngModel)]="currentUser.selectedRadio" value="all" (change)="handleChange($event,'all')" />

            <label class="custom-control-label" for="customRadio0">Toutes</label>
          </div>

        </div>
      </div>
    </li>


    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="listOrg.length">
        <ng-container *ngFor="let product of listOrg">
          <div class="media align-items-center">
            <div class="media-body">
              <div class="custom-control custom-radio">

                <h6 class="cart-item-title">
                  <input type="radio" id="customRadio_{{product._id}}" name="customRadio" class="custom-control-input"
                    (change)="handleChange($event,product)" [(ngModel)]="currentUser.selectedRadio"
                    value="customRadio_{{product._id}}" />

                  <label class="custom-control-label"
                    for="customRadio_{{product._id}}">{{product.organisationName}}</label>
                </h6>
              </div>

            </div>
          </div>
        </ng-container>
      </ng-container>
    </li>
    <!--/ Cart content -->
  </ul>
</li>