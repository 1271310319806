import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Organisation } from './organisation.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  // selectedOrganisation: Organisation = {
  //   organisationName: '',
  //   description: '',
  //   accountStripe: {
  //     email: ''
  //   }
  // };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  postOrganisation(organisation: Organisation) {
    return this.http.post(environment.apiBaseUrl + '/organisation/register', organisation, this.noAuthHeader);
  }

  postConfirmdata(id, oData) {
    return this.http.post(environment.apiBaseUrl + '/organisation/confirmdata', oData);
  }


  postOrganisationBiss(organisation) {
    return this.http.post(environment.apiBaseUrl + '/organisation/register', organisation, this.noAuthHeader);
  }

  getOrganisation() {
    return this.http.get(environment.apiBaseUrl + '/organisation');
  }

  getAllOrganisation() {
    return this.http.get(environment.apiBaseUrl + '/organisation/all');
  }

  getOrganisationById(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/' + id);
  }

  getUsersByOrganisationId(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/user/' + id);
  }

  activeAccountUserOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/user/account/active/' + id);
  }

  disableAccountUserOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/user/account/disable/' + id);
  }

  stripeActiveAccountUserOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/user/account/stripe/active/' + id);
  }

  stripeDisableAccountUserOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/user/account/stripe/disable/' + id);
  }

  getDocumentOrganisation(fileName) {
    return this.http.post(environment.apiBaseUrl + '/organisation/document/identity', fileName);
  }

  update(organisation: Organisation) {
    return this.http.post(environment.apiBaseUrl + '/organisation/update', organisation);
  }

  createExternalAccountStripe(infoOrganization) {
    return this.http.post(environment.apiBaseUrl + '/organisation/external/account/add', infoOrganization);
  }

  updateExternalAccountStripe(infoOrganization) {
    return this.http.post(environment.apiBaseUrl + '/organisation/external/accountstripe/update', infoOrganization);
  }

  updateControlIdentity(controlIdentity) {
    return this.http.post(environment.apiBaseUrl + '/organisation/control/identity/update', controlIdentity);
  }

  deleteIdentity(sRV) {
    return this.http.post(environment.apiBaseUrl + '/organisation/deleteidentiy/' + sRV, {});
  }

  organizationAgregateCampaign(id) {
    return this.http.get(environment.apiBaseUrl + '/don/organisation/' + id);
  }

  organizationDashBoardOpened(id) {
    return this.http.post(environment.apiBaseUrl + '/user/organisation/increment/' + id, null);
  }


  getRNAInformation(rna) {
    //W131016427
    var url = "https://entreprise.data.gouv.fr/api/rna/v1/id/"+rna;
    return this.http.get(url);

  }


}
