import { Component, EventEmitter, OnInit, ViewChild, Input, Output } from '@angular/core';
import { User } from 'app/auth/models';
import { Subject } from 'rxjs';
import { sample, takeUntil } from 'rxjs/operators';
import { AuthenticationService, } from 'app/auth/service';
import { UserService } from 'app/main/shared/user.service';
import { TransactionService } from 'app/main/shared/transaction.service';
import { StripeTransactionService } from 'app/main/shared/stripeTransaction.service';
import { OrganisationService } from 'app/main/shared/organisation.service';
import { KpiService } from 'app/main/shared/kpi.service';
import { Dashboarddata } from 'app/main/shared/dashaboardata';
import { DatatablesService } from 'app/main/dashboard/datatables.service';
import { Router } from '@angular/router';
// import { EcommerceService } from 'app/main/apps/ecommerce/ecommerce.service';


@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  // Public
  public products = [];
  public cartList = [];
  public cartListLength;
  public currentUser: User;
  public listOrg = [];
  dashboarddata: Dashboarddata;
  // Private
  private _unsubscribeAll: Subject<any>;
  //inject child component to use its own functions.


  /**
   *
   * @param {EcommerceService} _ecommerceService
   */
  // constructor(public _ecommerceService: EcommerceService) {
  //   this._unsubscribeAll = new Subject();
  // }
  constructor(public _authenticationService: AuthenticationService, private userService: UserService,
    private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService,
    private organisationService: OrganisationService,
    private kpiService: KpiService,
    private _datatablesService: DatatablesService,
    private _router: Router) {

    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService, _router);
    this._unsubscribeAll = new Subject();
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

  }
  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove From Cart
   *
   * @param product
   */
  removeFromCart(product) {
    if (product.isInCart === true) {

    }
  }



  handleChange(oEvent, oValue): void {
    let on = oEvent.target.value;
    let value = oValue;
    if (oValue !== undefined && oValue !== null) {
      if (oValue == "all") {
        this.currentUser.idOrg = "all";
        this.currentUser.selectedOrg._id = this.currentUser.idOrg;
        this.currentUser.selectedOrg.organisationName = "Toutes";
        // this.currentUser.idOrg = "5f49510be51daf6e388de817";
        this._authenticationService.changeSelectOrg(this.currentUser)

      } else {
        this.currentUser.selectedOrg._id = oValue._id;
        this.currentUser.selectedOrg.organisationName = oValue.organisationName;
        this.currentUser.idOrg = oValue._id;
        this._authenticationService.changeSelectOrg(this.currentUser)
      }

      //      this.reloadCurrentRoute();
      // this._datatablesService.getDataTableRows().then(res => {

      //   this._datatablesService.onDatatablessChanged.next(res);
      //   //window.location.reload();
      //   this.reloadCurrentRoute();
      // });;











      this.dashboarddata.formatDataDashboardAll(this);

      this.refreshDataByRouteUrl();
    }



  }

  refreshDataByRouteUrl() {
    let currentUrl = this._router.url;

    let route = {
      transaction: ['/dashboard/transactions'],
      campaign: ['/dashboard/campaign', '/dashboard/campaign/campaign-list'],
      donator: ['/dashboard/donator'],
      transfer: ['/dashboard/transfert'],
      kiosk: ['/dashboard/kiosk'],
      kioskdetails: ['/dashboard/kioskdetails'],
      chart: ['/dashboard/chart'],
    };

    if (route.transaction.indexOf(currentUrl) > -1) {
      this._datatablesService.getDataTableRows();
    }
    if (route.campaign.indexOf(currentUrl) > -1) {
      this._datatablesService.getCampaignRows();
    }
    if (route.donator.indexOf(currentUrl) > -1) {
      this._datatablesService.getDonatorRows();
      this._datatablesService.getDonatorTransactionRows();
    }

    if (route.transfer.indexOf(currentUrl) > -1) {
      this._datatablesService.getPayoutList();
    }
    if (route.kiosk.indexOf(currentUrl) > -1) {
      this._datatablesService.getKioskList();
    }

    if (route.kioskdetails.indexOf(currentUrl) > -1) {
      this._datatablesService.getKioskDetailsList();
    }

    if (route.kioskdetails.indexOf(currentUrl) > -1) {
      this._datatablesService.getKioskChartList();
    }
    if (currentUrl.startsWith("/dashboard/kioskdetails/")) {
      this._datatablesService.getKioskChartList();
    }

    if (route.chart.indexOf(currentUrl) > -1) {
      this._datatablesService.getReportChartList();
    }
  }



  reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {


    this.cartListLength = 0;

    this._authenticationService.currentUserSubject.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      if (res !== null) {
        this.currentUser = res;
        this.cartListLength = this.currentUser.listOrg.length;
        this.listOrg = this.currentUser.listOrg;
      }
    });
  }


}
