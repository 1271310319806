import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUserO: User;
  public currentUser: Observable<User>;
  public isLocalStorage: boolean;
  //private
  public currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();



  }

  // getter: currentUserValue
  public get currentUserValue_old(): User {
    this.currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUserSubject !== undefined && this.currentUserSubject !== null) {
      if (this.currentUserSubject.value !== undefined && this.currentUserSubject.value !== null) {
        return this.currentUserSubject.value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  get idOrganisation() {
    let idOrg = null;
    if (this.currentUser !== undefined && this.currentUser !== null) {
      idOrg = this.currentUserSubject.value.idOrg;
    }
    return idOrg;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  setListOrg(aListOrg, idSelected, user) {
    user.allIsChecked = true;
    user.selectedRadio = 'all';
    user.selectOrg = false;

    user.selectedOrg._id = 'all';
    user.selectedOrg.organisationName = "Toutes";

    if (Array.isArray(aListOrg)) {
      if (aListOrg.length > 0) {
        user.selectOrg = true;
        if (aListOrg.length < 2) {
          user.selectOrg = false;
          user.selectedOrg._id = aListOrg[0]._id;
          user.selectedOrg.organisationName = aListOrg[0].organisationName;
          user.idOrg = user.selectedOrg._id;
          idSelected = user.idOrg;
        }
      }
      aListOrg.forEach(element => {
        element.isChecked = false;
        if (element._id === idSelected) {
          element.isChecked = true;
          user.allIsChecked = false;
          user.selectedRadio = "customRadio_" + element._id;
        }

      });
    }

    if (user.selectedRadio == 'all') {
      user.idOrg = 'all';
    }
    return aListOrg;
  }

  initListOrg(aListOrg, defaultOrg) {
    let listOrg = aListOrg;
    if (!Array.isArray(aListOrg)) {
      listOrg = [];
      listOrg.push({
        _id: defaultOrg
      });
    }
    return listOrg;
  }
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/api/authenticate`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.role !== undefined && user.role !== null) {
              if (user.role === "adminOrganisation") {
                user.role = "Admin";
              }
            }

            user.selectedOrg = { _id: "", organisationName: "" };
            this.setListOrg(user.listOrg, user.selectedOrg._id, user);
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'Connexion réussie ', '👋 Bienvenue !',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
              this.isLocalStorage = true;
            }, 2500);

            // notify
            this.currentUserSubject.next(user);


          }

          return user;
        })
      );
  }

  changeSelectOrg(user: User) {

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);

  }


  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
