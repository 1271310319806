import { Role } from './role';

export class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: Role;
  idOrg: string;
  token?: string;
  selectOrg: boolean;
  allIsChecked: string;
  selectedRadio: string;
  listOrg: [
    {
      _id: string,
      organisationName: string,
      isChecked: string
    }
  ];
  selectedOrg: {
    _id: string,
    organisationName: string,
    isChecked: string
  }
}
