import { serialize } from "v8";

var _ = require('lodash');

export interface Month {
    month: number;
    month1: string;
    monthText: string;
    monthTextShort: string;
}

export class formatter {

    month: Array<string>;
    monthShort: Array<string>;
    days: Array<string>;
    daysShort: Array<string>;
    curentDate = {
        dayWeek: '',
        dayMonth: 0,
        month: '',
        years: '',
        monthShort: '',
        text: '',
        formatdate: '',
        prefixdate: '',

    };
    constructor() {
        this.month = this.setMonth();
        this.monthShort = this.setMonthShort();
        this.days = this.setDay();
        this.daysShort = this.setDaysShort();
        this.defineDate();


    }


    defineDate() {

        const date = new Date();
        const dayWeek = date.getDay();
        const dayMonth = date.getDate();
        const month = date.getMonth() + 1;
        const years = date.getFullYear();
        this.curentDate.dayMonth = dayMonth;
        this.curentDate.years = years.toString();
        const days = this.days;
        const monthsShort = this.monthShort;

        for (let i = 0; i < days.length; i++) {
            if (dayWeek - 1 === i) {
                this.curentDate.dayWeek = days[i];
            }
        }

        for (let i = 0; i < monthsShort.length; i++) {
            if (month - 1 === i) {
                this.curentDate.monthShort = monthsShort[i];
            }
        }
    }

    defineDate2(oPeriode, oinfoDate) {
        var periode = _.cloneDeep(this.curentDate);
        periode.prefixdate = oinfoDate.prefixdate;
        periode.formatdate = oinfoDate.formatdate;

        if (oPeriode !== undefined && oPeriode !== null
            && oPeriode.date !== undefined
            && oPeriode.date !== null
        ) {
            const date = new Date(oPeriode.date);
            const dayWeek = date.getDay();
            const dayMonth = date.getDate();
            const month = date.getMonth() + 1;
            const years = date.getFullYear();
            periode.dayMonth = dayMonth;
            periode.years = years.toString();
            const days = this.days;
            const monthsShort = this.monthShort;

            for (let i = 0; i < days.length; i++) {
                if (dayWeek - 1 === i) {
                    periode.dayWeek = days[i];
                }
            }

            for (let i = 0; i < monthsShort.length; i++) {
                if (month - 1 === i) {
                    periode.monthShort = monthsShort[i];
                }
            }

            periode.text = this.formtDateText(periode.prefixdate, periode, periode.formatdate);
        }
        return periode;
    }
    formtDateText(sPrefix, oDate, sFormat) {

        var txtFormat = sPrefix;
        var spaceStart = " ";
        var space = " ";

        if (txtFormat == "")
            spaceStart = "";

        switch (sFormat) {
            case "DDddMMMAAAA":
                txtFormat = txtFormat.concat(spaceStart, oDate.dayWeek, space, oDate.dayMonth, space, oDate.monthShort, space, oDate.years);
                break;
            case "ddMMMAAAA":
                txtFormat = txtFormat.concat(spaceStart, oDate.dayMonth, space, oDate.monthShort, space, oDate.years);
                break;
            case "ddMMMAA":
                txtFormat = txtFormat.concat(spaceStart, oDate.dayMonth, space, oDate.monthShort, space, oDate.years.slice(-2));
                break;
            case "MMMAAAA":
                txtFormat = txtFormat.concat(spaceStart, oDate.monthShort, space, oDate.years);
                break;

            case "AAAA":
                txtFormat = txtFormat.concat(spaceStart, oDate.years);
                break;

        }

        return txtFormat;

    }


    setDay() {

        let days = [];
        days.push('Lundi');
        days.push('Mardi');
        days.push('Mercredi');
        days.push('Jeudi');
        days.push('Vendredi');
        days.push('Samedi');
        days.push('Dimanche');
        return days;

    }

    setDaysShort() {

        let days = [];
        days.push('Lundi');
        days.push('Mardi');
        days.push('Mercredi');
        days.push('Jeudi');
        days.push('Vendredi');
        days.push('Samedi');
        days.push('Dimanche');
        return days;

    }

    setMonth() {

        let months = [];
        months.push('Janvier');
        months.push('Février');
        months.push('Mars');
        months.push('Avril');
        months.push('Mai');
        months.push('Juin');
        months.push('Juillet');
        months.push('Aout');
        months.push('Septembre');
        months.push('Octobre');
        months.push('Novembre');
        months.push('Décembre');

        return months;

    }

    setMonthShort() {
        let monthsShort = [];
        monthsShort.push('Janv.');
        monthsShort.push('Févr.');
        monthsShort.push('Mars');
        monthsShort.push('Avr.');
        monthsShort.push('Mai');
        monthsShort.push('Juin');
        monthsShort.push('Juill.');
        monthsShort.push('Aout');
        monthsShort.push('Sept.');
        monthsShort.push('Oct.');
        monthsShort.push('Nov.');
        monthsShort.push('Déc.');

        return monthsShort;

    }

    getMonthListForCard() {
        let months = this.setMonth();
        let monthShort = this.setMonthShort();


        let listMonth: Month[] = [];
        let index = 0;
        months.forEach(element => {
            index += 1;
            let model = {
                month: index,
                monthText: element,
                monthTextShort: monthShort[index - 1],
                month1: index.toString().padStart(2, '0'),
                montTextLabelShort: index.toString().padStart(2, '0') + " " + monthShort[index - 1]
            };
            listMonth.push(model);


        });

        return listMonth;

    }
    format(flagCompress, value, currencySign, decimalLength, decimalDelimiter, chunkLength, chunkDelimiter): string {
        //value /= 100;
        try {

            if (flagCompress) {
                if (!isNaN(value)) {

                    if (value >= 1000000) {
                        value = value / 10000;
                        currencySign = ' To' + currencySign;
                    }

                    else if (value >= 100000) {
                        value = value / 1000;
                        currencySign = ' K' + currencySign;
                    }

                }



            }

            let num = value.toString();
            if (value.toFixed !== undefined) {
                num = value.toFixed(Math.max(0, ~~decimalLength));
            } else {

                if (flagCompress) {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                } else {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                }
                console.log("value : " + num);
            }


            let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter) + currencySign;
        } catch (error) {
            console.log("error formatPipe");
            console.log(error);
            return value.toString();
        }
    }


    formatDate(date) {
        let monthDate = date.getMonth() + 1;
        let day = date.getDate();

        if (monthDate < 10) {
            monthDate = '0' + monthDate;
        }

        if (day < 10) {
            day = '0' + day;
        }
        return day + '/' + monthDate + '/' + date.getFullYear();
    }

    checkUndefined(oData, sField) {
        let aField = [];
        let undef = false;
        if (oData != undefined) {
            if (sField !== undefined && sField !== null) {
                if (typeof sField === "string") {
                    aField = sField.split('.');
                }
            }

            if (aField.length > 0) {
                let prop = aField[0];
                let index = 0;
                while (undef == false && index < aField.length) {
                    if (oData[prop] != undefined && oData[prop] != null) {
                    } else {
                        undef = true;
                    }
                    index++;
                    if (index < aField.length)
                        prop = prop + '.' + aField[index];
                }

            }
        } else {
            undef = true;
        }

        return undef;
    }
}



