import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { StripeTransactionService } from './../shared/stripeTransaction.service';
import { formatter } from './../shared/formatter';
import { User } from 'app/auth/models';
import { CardAnalyticsData } from './customer.service';
import { colors } from 'app/colors.const';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CardStatisticsService implements Resolve<any> {

  // Color Variables
  private primaryColorShade = '#836AF9';
  private yellowColor = '#ffe800';
  private successColorShade = '#28dac6';
  private warningColorShade = '#ffe802';
  private warningLightColor = '#FDAC34';
  private infoColorShade = '#299AFF';
  private greyColor = '#4F5D70';
  private blueColor = '#2c9aff';
  private blueLightColor = '#84D0FF';
  private greyLightColor = '#EDF1F4';
  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private lineChartPrimary = '#666ee8';
  private lineChartDanger = '#ff4961';
  private labelColor = '#6e6b7b';
  private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout
  private formatter: formatter;
  private monthlyYear: number;
  public dailyStarDate: Date;
  private nBDay: number;
  private currentDate: Date;
  rows: any;
  rows2: any;
  dataMonthly: any;
  dataDaily: any;
  public currentUser: User;
  errorServer: Boolean = false;
  onCardSatatsChanged: BehaviorSubject<any>;
  dataAnalytics: CardAnalyticsData;
  onCardSatatsAnalyticsChanged: BehaviorSubject<any>;
  onCardSatatsAnalyticsMonthlyChanged: BehaviorSubject<any>;
  onCardSatatsAnalyticsDailyChanged: BehaviorSubject<any>;
  public currentListoObs: Observable<any>;
  public currentListoObs1: Observable<any>;
  public currentListoObs2: Observable<any>;
  public currentListoObs3: Observable<any>;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private stripeTransactionService: StripeTransactionService,) {
    // Set the defaults
    this.onCardSatatsChanged = new BehaviorSubject({});
    this.onCardSatatsAnalyticsChanged = new BehaviorSubject({});
    this.onCardSatatsAnalyticsMonthlyChanged = new BehaviorSubject({});
    this.onCardSatatsAnalyticsDailyChanged = new BehaviorSubject({});
    this.dataAnalytics = CardAnalyticsData.data;
    this.formatter = new formatter();
    this.currentDate = new Date();
    this.monthlyYear = this.currentDate.getFullYear();
    this.dailyStarDate = new Date();
    this.nBDay = 15;


    this.currentListoObs = this.onCardSatatsChanged.asObservable().pipe(distinctUntilChanged());
    this.currentListoObs1 = this.onCardSatatsAnalyticsChanged.asObservable().pipe(distinctUntilChanged());
    this.currentListoObs2 = this.onCardSatatsAnalyticsMonthlyChanged.asObservable().pipe(distinctUntilChanged());
    this.currentListoObs3 = this.onCardSatatsAnalyticsDailyChanged.asObservable().pipe(distinctUntilChanged());

  }

  setPeriodeMonthly(sYear: number): void {
    this.monthlyYear = sYear;
    this.getDataChartMonthly();
  }

  setNbDay(nNbDay: number): void {
    this.nBDay = nNbDay;
  }

  setPeriodeDaily(dDateStart: Date, nbDay?: number): void {
    this.dailyStarDate = dDateStart;
    this.nBDay = 15;
    if (nbDay !== undefined && nbDay !== null) {
      this.nBDay = nbDay;
    }
    this.getDataChartDaily();
  }
  getPeriodeDaily(): Date {
    return this.dailyStarDate;
  }


  refreshData() {
    this.getDataTableRows();
    this.getDataTableCustomer();
    this.getDataChartMonthly();
    this.getDataChartDaily();
  }
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getDataTableRows(),
        this.getDataTableCustomer(),
        this.getDataChartMonthly(),
        this.getDataChartDaily()

      ]).then(() => {
        resolve();
      }, reject);
    });
  }
  resolve_tablerows(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }
  resolve_tableCustomer(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableCustomer()]).then(() => {
        resolve();
      }, reject);
    });
  }
  resolve_chartMonthly(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataChartMonthly()]).then(() => {
        resolve();
      }, reject);
    });
  }
  resolve_chartDaily(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataChartDaily()]).then(() => {
        resolve();
      }, reject);
    });
  }
  onSetDataCampaignDonut(aData): void {
    let dataFormat = {
      series: [],
      analyticsData: {},
      analyticsDataInfo: [],
      labels: []
    };

    if (aData !== undefined && aData !== null) {
      let sum = 0;
      aData.forEach(element => {
        //sum += element.sumBrut + 2000;
        sum = element.sumBrut;
        dataFormat.series.push(sum);
        dataFormat.analyticsData[element.title] = sum;
        dataFormat.labels.push(element.title);
        dataFormat.analyticsDataInfo.push({ sumBrut: sum, title: element.title });

      });

    }

    this.rows2.campaign = dataFormat;
    this.dataAnalytics = this.rows2;
  }

  onSetDataMonthlyChart(aData): void {
    let dataFormat = {
      series: [],
      analyticsData: {},
      analyticsDataInfo: [],
      labels: []
    };

    let series = {
      name: "Collecte du mois",
      data: []
    }

    if (aData !== undefined && aData !== null) {
      let sum = 0;
      aData.forEach(element => {
        sum += element.y;
        series.data.push(element.y);
        dataFormat.analyticsData[element.label] = element.y;
        dataFormat.labels.push(element.label);
        dataFormat.analyticsDataInfo.push({ sumBrut: element.y, title: element.label });

      });

      dataFormat.series.push(series);

    }

    this.dataMonthly = dataFormat;

  }


  onSetDataDailyChart(aData): void {
    let dataFormat = {
      series: [],
      analyticsData: {},
      analyticsDataInfo: [],
      labels: []
    };

    let dataLabel = [];

    dataFormat.series
    if (aData !== undefined && aData !== null) {
      let sum = 0;
      aData.forEach(element => {

        let datasets =
        {
          data: [],
          label: 'Europe',
          // borderColor: this.lineChartDanger,
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: this.lineChartDanger,
          fill: false,
          pointRadius: 5,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'black',
          pointHoverBorderColor: colors.solid.white,
          pointHoverBackgroundColor: this.lineChartDanger,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: this.tooltipShadow
        }


        element.dataPoints.forEach(element_2 => {
          datasets.data.push(element_2.y);
          dataLabel.push({ label: element_2.label, date: element_2.date });
        });
        datasets.label = element.name;
        dataFormat.series.push(datasets);

      });

      let dateLabel = this.convertDataDailyLabel(dataLabel);
      dataFormat.labels = dateLabel;
    }

    this.dataDaily = dataFormat;

  }
  convertDataDailyLabel(aData) {

    let dataLabel = [];
    let infoDate = {
      prefixdate: "",
      formatdate: "ddMMMAA"
    }
    let that = this;
    aData.forEach(element => {
      let periode = {
        date: element.date
      }
      let dateFormat = this.formatter.defineDate2(periode, infoDate);
      if (dataLabel.indexOf(dateFormat.text) == -1) {
        dataLabel.push(dateFormat.text);
      }

    });

    return dataLabel;

  }
  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null) {
      return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/apikpi/org/tx/sum/year/${this.currentUser.idOrg}`).subscribe((response: any) => {
          this.rows = response;
          this.onCardSatatsChanged.next(this.rows);
          resolve(this.rows);
        }, reject);
      });
    }
  }



  /**
 * Get rows
 */
  getDataTableCustomer(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null) {
      return new Promise((resolve, reject) => {

        this.stripeTransactionService.getSumCampaignTransactions(this.currentUser.idOrg).subscribe(
          res => {


            //this.rows2 = CardAnalyticsData.data;
            this.rows2 = res['campaign'];
            this.onSetDataCampaignDonut(res['campaign']);
            this.onCardSatatsAnalyticsChanged.next(this.dataAnalytics);

            resolve(this.rows2);

          },
          err => {
            this.errorServer = true;
          }

        );
      });
    }
  }


  /**
 * Get rows
 */
  getDataChartMonthly(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null) {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      if (this.monthlyYear !== undefined && this.monthlyYear !== null) {
        currentYear = this.monthlyYear;
      }
      let startDate = new Date();
      startDate.setFullYear(currentYear, 11, 31);
      let nbMonth = 11;
      return new Promise((resolve, reject) => {
        this.stripeTransactionService.getSumCampaignTransactionsPerMonth(startDate, nbMonth, this.currentUser.idOrg).subscribe(
          res => {

            this.onSetDataMonthlyChart(res['transactions']);
            //this.onSetDataMonthlyChart(CardAnalyticsData.data.monthlyTransaction.transactions);
            this.onCardSatatsAnalyticsMonthlyChanged.next(this.dataMonthly);

            resolve(this.dataMonthly);

          },
          err => {
            this.errorServer = true;
          }

        );
      });
    }
  }


  /**
 * Get rows
 */
  getDataChartDaily(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null) {
      let startDate = this.dailyStarDate;
      let nbdays = this.nBDay;
      return new Promise((resolve, reject) => {
        this.stripeTransactionService.getSumTransactionsByCampaignPerDay(startDate, nbdays, this.currentUser.idOrg).subscribe(
          res => {


            //this.onSetDataDailyChart(CardAnalyticsData.data.dailyCampaign.campaign);
            this.onSetDataDailyChart(res['campaign']);
            this.onCardSatatsAnalyticsDailyChanged.next(this.dataDaily);

            resolve(this.dataDaily);

          },
          err => {
            this.errorServer = true;
          }

        );
      });
    }
  }
}