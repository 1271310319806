import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  kpidonationsumtransaction(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/sum/year/' + id);
  }

  kpidonationsumtransaction2(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/sum/year/2/' + id);
  }

  kpidonationsumtransactionAll(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/sum/year/All/' + id);
  }

  kpidonationsumtransactionmonth(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/sum/month/' + id);
  }

  kpidonationsumtransactionday(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/sum/today/' + id);
  }

  kpinbemailtransactionall(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/nbemail/all/' + id);
  }

  kpinbemailtransactionall2(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/nbemail2/all/' + id);
  }

  kpinbemail(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/nbemail/kpi/' + id);
  }





  kpipayouttransactionall(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/po/sum/year/' + id);
  }

  kpikiosktransactionall(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/' + id + '/' + idKiosk);
  }
  kpikiosktransactionyear(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/year/' + id + '/' + idKiosk);
  }
  kpikiosktransactionmonth(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/month/' + id + '/' + idKiosk);
  }
  kpikiosktransactiontoday(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/today/' + id + '/' + idKiosk);
  }


  kpikiosktransactionnbemailtoday(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/nbemail/today/' + id + '/' + idKiosk);
  }
  
  kpikiosktransactionnbemailmonth(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/nbemail/month/' + id + '/' + idKiosk);
  }

  kpikiosktransactionnbemailyear(id,idKiosk) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/kiosk/sum/nbemail/year/' + id + '/' + idKiosk);
  }


  kpidonationcampaignsumtransactionall(id,idCampaign) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/campaign/sum/all/' + id + "/" + idCampaign);
  }

  kpidonationcampaignsumtransaction(id,idCampaign) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/campaign/sum/year/' + id + "/" + idCampaign);
  }
  kpidonationcampaignsumdaytransaction(id,idCampaign) {
    return this.http.get(environment.apiBaseUrl2 + '/org/tx/campaign/sum/today/' + id + "/" + idCampaign);
  }

  kpifirstlastdate(id) {
    return this.http.get(environment.apiBaseUrl2 + '/org/firstLastdate/year/' + id);
  }

  







}
