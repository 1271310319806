import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeTransactionService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  transactionStripeByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/all/organization/' + id);
  }

  getTransactionStripeById(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/' + id);
  }

  getTransactionStripeByCampaign(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/' + id);
  }

  sumTransactionStripeByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/sum/' + id);
  }

  sumTransactionStripePerDayByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/day/sum/', {
      id: id,
      date: new Date()
    });
  }

  sumTransactionStripePerMonthByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/month/sum/', {
      id: id,
      date: new Date()
    });
  }

  lastDateTransactionStripeByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/date/last/' + id);
  }

  getSumCampaignTransactions(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/sum/' + id);
  }
  getSumCampaignKioskTransactions(id, idkiosk) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/sum/' + id + "/kiosk/" + idkiosk);
  }
  getSumTransactionsByCampaign(id) {

    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/bycampaign/sum', { id: id, data: new Date() });
  }

  getSumTransactionsByCampaignPerDay(startDate, nbDays, id) {
    const objectTransactions = {
      startDate: startDate,
      nbDays: nbDays,
      id: id
    };

    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/day/sum/', objectTransactions);
  }

  getSumCampaignTransactionsPerMonth(startDate, nbMonth, id) {
    const objectTransactions = {
      startDate: startDate,
      nbMonth: nbMonth,
      id: id
    };

    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/month/sum/', objectTransactions);
  }

  nbDonorByOrganisationByEmailNull(id) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/email/null/count', { id: id });
  }

  nbStripeDonorByOrganisationByEmailNull(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/email/null/count/' + id);
  }

  nbStripeDonorByOrganisationByEmail(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/email/count/' + id);
  }

  nbDonorByOrganisationByEmail(id) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/email/count', { id: id });
  }

  // getEmailDonorByOrganisation(id) {
  //   return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/group/email', {id: id});
  // }

  getStripeEmailDonorByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/group/email/' + id);
  }
  getTransactionOrganisationByEmail(id, email) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/donator', { id: id, email: email });
  }

  getStripeTransactionOrganisationByEmail(id, email) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/donator/' + id + '/' + email);
  }

  sumTransactionStripeByKioskOrganisation(idO, idK) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/organization/kiosk/sum/' + idO + '/' + idK);
  }

  getSumCampaignTransactionsByKiosk(idO, idK) {
    return this.http.get(environment.apiBaseUrl + '/stripe/transaction/campaign/organization/kiosk/sum/' + idO + '/' + idK);
  }

  getSumCampaignTransactionsPerMonthByKiosk(startDate, nbMonth, idO, idK) {
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/campaign/kiosk/month/sum/', {
      startDate: startDate,
      nbMonth: nbMonth,
      idO: idO,
      idK: idK
    });
  }

  getSumTransactionsByKioskCampaignPerDay(startDate, nbDays, idO, idK) {
    const objectTransactions = {
      startDate: startDate,
      nbDays: nbDays,
      idO: idO,
      idK: idK,

    };
    return this.http.post(environment.apiBaseUrl + '/stripe/transaction/organization/kiosk/campaign/day/sum/', objectTransactions);
  }





}
