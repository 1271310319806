import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'bar-chart-2',
    // badge: {
    //   title: '2',
    //   translate: 'MENU.DASHBOARD.BADGE',
    //   classes: 'badge-light-warning badge-pill'
    // }, 
    children: [
      {
        id: 'Dashboard_day',
        title: 'Suivi du jour',
        translate: 'MENU.DASHBOARD.DAY',
        type: 'item',
        //  role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'activity',
        url: 'dashboard/today'
      },
      {
        id: 'Dashboard_month',
        title: 'Suivi du mois',
        translate: 'MENU.DASHBOARD.MONTH',
        type: 'item',
        //  role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'activity',
        url: 'dashboard/month'
      },
      {
        id: 'Dashboard_year',
        title: 'Suivi année',
        translate: 'MENU.DASHBOARD.YEAR',
        type: 'item',
        //  role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'activity',
        url: 'dashboard/year'
      },
      {
        id: 'Dashboard_chart',
        title: 'Statistiques',
        translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        //  role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'activity',
        url: 'dashboard/chart'
      }
    ]
  },
  {
    id: 'transaction',
    title: 'Transaction',
    translate: 'MENU.TRANSACTION',
    type: 'item',
    icon: 'circle',
    url: 'dashboard/transactions'
  },
  {
    id: 'campaign',
    title: 'Campagne',
    translate: 'MENU.CAMPAIGN',
    type: 'item',
    icon: 'circle',
    // url: 'dashboard/campaign'
    // url: 'dashboard/campaign/campaign-list'
    url: 'dashboard/campaign'
  },

  {
    id: 'donator',
    title: 'Donateur',
    translate: 'MENU.DONATOR',
    type: 'item',
    icon: 'circle',
    url: 'dashboard/donator'
  },

  {
    id: 'transfert',
    title: 'Versement',
    translate: 'MENU.PAYOUT',
    type: 'item',
    icon: 'circle',
    url: 'dashboard/transfert'
  },


  // {
  //   id: 'payout',
  //   title: 'Versement',
  //   translate: 'MENU.PAYOUT',
  //   type: 'item',
  //   icon: 'circle',
  //   url: 'dashboard/transfer'
  // },
  {
    id: 'kiosk',
    title: 'Kiosk',
    translate: 'MENU.KIOSK',
    type: 'item',
    icon: 'circle',
    url: 'dashboard/kiosk'
  },

  // {
  //   id: 'receipt',
  //   title: 'Recu',
  //   translate: 'MENU.RECEIPT',
  //   type: 'item',
  //   icon: 'circle',
  //   url: 'dashboard/receipt/list'
  // },
  // {
  //   id: 'generatereceipt',
  //   title: 'Import transactions',
  //   translate: 'MENU.TRANSACTIONIMPORT',
  //   type: 'item',
  //   icon: 'circle',
  //   url: '/dashboard/donator/transaction/fiscal/generator'
  // }
]
