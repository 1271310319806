import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';

import { User, Role } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private currentUserSubject: BehaviorSubject<User>;
  private isLocalStorage: boolean;
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {
    this.isLocalStorage = false;
  }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this._authenticationService.isLocalStorage) {
      var checkLocalStorage = this.checkLocalStorage();
      if (!checkLocalStorage) {
        this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }

    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  checkLocalStorage() {
    this.currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUserSubject !== undefined && this.currentUserSubject !== null) {

      return true;

    }
    return false;
  }

}
