import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from './user.model';
import { Organisation } from './organisation.model';
import { AuthenticationService } from 'app/auth/service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _unsubscribeAll: Subject<any>;
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: any;
  selectedUser = {
    fullName: '',
    email: '',
    password: ''
  };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  // constructor(private http: HttpClient) { }
  constructor(private http: HttpClient, private _authenticationService: AuthenticationService) {

    this._unsubscribeAll = new Subject();
    this._authenticationService.currentUserSubject.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.currentUser = res;
    });


  }

  public getAuthenticateService(): AuthenticationService {
    return this._authenticationService;
  }



  //HttpMethods



  postUser(user: User) {
    return this.http.post(environment.apiBaseUrl + '/register', user, this.noAuthHeader);
  }

  postUserAdmin(user) {
    return this.http.post(environment.apiBaseUrl + '/user/admin/register', user);
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/authenticate', authCredentials, this.noAuthHeader);
  }

  disableAccount(id) {
    return this.http.post(environment.apiBaseUrl + '/user/account/disable', id);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
    // return this.http.get(environment.apiBaseUrl + '/dashboard');
  }

  getOrganisationByUserId(id) {
    return this.http.get(environment.apiBaseUrl + '/user/organisation/' + id);
  }

  activeUserAccount(id: string) {
    let objectid = {
      confirmId: id
    };
    return this.http.post(environment.apiBaseUrl + '/activeAccount', objectid, this.noAuthHeader);
  }
  updatePassword(objectUser) {
    return this.http.post(environment.apiBaseUrl + '/updatepassword', objectUser);
  }
  updatePassword2(objectUser) {
    return this.http.post(environment.apiBaseUrl + '/pwdupdt', objectUser);
  }
  updatePasswordIdCheck(objectUser: any, id: String) {
    return this.http.post(environment.apiBaseUrl + '/user/password/update/' + id, objectUser);
  }

  //Helper Methods

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('currentUser');
    //return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else {
      return null;
    }
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  initPasswordUser(email) {
    return this.http.post(environment.apiBaseUrl + '/user/password/init', { id: email }, this.noAuthHeader);
  }

  controlIdUser_old(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }

  controlIdUser(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }


  getDonatorAddress(sEmail) {
    return this.http.get(environment.apiBaseUrl + '/rf/5/' + sEmail);
  }

  getSendReceiptFiscal(sId, oData) {
    return this.http.post(environment.apiBaseUrl + '/rf/6/' + sId, oData);
  }

  getSendSavePackDonatorReceipt(sId, oData) {
    return this.http.post(environment.apiBaseUrl + '/rf/7/' + sId, oData);
  }


  addNewUserDonator(sId, oData) {
    return this.http.post(environment.apiBaseUrl + '/rf/add/UserDonator/' + sId, oData);
  }



}
