import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { OrganisationService } from './organisation.service';
import { StripeTransactionService } from './stripeTransaction.service';
import { TransactionService } from './transaction.service';
import { KpiService } from './kpi.service';
import { formatter } from './formatter';



import { HttpClient, HttpHeaders } from '@angular/common/http';
var _ = require('lodash');
import { CountUp } from "countup.js";
import { AuthenticationService } from 'app/auth/service';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from './user.model';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class Dashboarddata {

    private _unsubscribeAll: Subject<any>;
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: any;

    private errorServer: Boolean = false;
    formatter: formatter;
    userDetails: object = {};
    curentDate = {
        dayWeek: '',
        dayMonth: 0,
        month: '',
        years: '',
        monthShort: '',
        text: '',
        formatdate: '',
        prefixdate: '',

    };

    public static delay = 200;
    public static delayRefreshdata = 500;

    model: object = {
        messageTexte: "",
        date: "",
        datafrom: 0,
        datato: 2000,
        datadecimals: 0,
        datarefreshinterval: 10,
        dataspeed: "2500",

        countDonationDay: undefined,
        suffixOld: "€",
        suffix: "€",
        data: 0,
        type: "numeric",
        infoDate: {
            text: ""
        }
    }

    lastDateTransaction: string;

    modelDonDay = _.cloneDeep(this.model);
    modelDonYear = _.cloneDeep(this.model);
    modelDonMonth = _.cloneDeep(this.model);
    modelNbTransaction = _.cloneDeep(this.model);
    modelNbDonator = _.cloneDeep(this.model);
    modelDonAverage = _.cloneDeep(this.model);

    modelDashboard = {
        //Current Day
        donationday: _.cloneDeep(this.modelDonDay),
        nbdonator: _.cloneDeep(this.modelNbDonator),
        donationaverage: _.cloneDeep(this.modelDonAverage),
        nbtransaction: _.cloneDeep(this.modelNbTransaction),
        //Current Year
        donationyear: _.cloneDeep(this.modelDonYear),
        donationyearaverage: _.cloneDeep(this.modelDonAverage),
        yearnbdonator: _.cloneDeep(this.modelNbDonator),
        yearnbtransaction: _.cloneDeep(this.modelNbTransaction),
        //Current Month
        donationmonth: _.cloneDeep(this.modelDonMonth),
        donationmonthaverage: _.cloneDeep(this.modelDonAverage),
        monthnbdonator: _.cloneDeep(this.modelNbDonator),
        monthnbtransaction: _.cloneDeep(this.modelNbTransaction),
        //View Transaction 
        donationtotalgen: _.cloneDeep(this.modelDonMonth),
        donationtotalnet: _.cloneDeep(this.modelDonMonth),
        donationtotalfee: _.cloneDeep(this.modelDonMonth),

        //View Donation
        donationwithemail: _.cloneDeep(this.modelDonMonth),
        donationwithoutemail: _.cloneDeep(this.modelDonMonth),
        donationnbtotal: _.cloneDeep(this.modelDonMonth),


        //View Payout
        payoutwaiting: _.cloneDeep(this.modelDonMonth),
        payoutinprogress: _.cloneDeep(this.modelDonMonth),
        payoutfinished: _.cloneDeep(this.modelDonMonth),

        //View Kiosk Donation
        donationkiosk: _.cloneDeep(this.modelDonMonth),
        donationkiosknbtransaction: _.cloneDeep(this.modelDonMonth),
        donationkioskaverage: _.cloneDeep(this.modelDonMonth),

        donationkiosktoday: _.cloneDeep(this.modelDonMonth),
        donationkiosktodaynbtransaction: _.cloneDeep(this.modelDonMonth),
        donationkiosktodayaverage: _.cloneDeep(this.modelDonMonth),
        donationkiosktodaynbdonator: _.cloneDeep(this.modelDonMonth),


        donationkioskyear: _.cloneDeep(this.modelDonMonth),
        donationkioskyearnbtransaction: _.cloneDeep(this.modelDonMonth),
        donationkioskyearaverage: _.cloneDeep(this.modelDonMonth),
        donationkioskyearnbdonator: _.cloneDeep(this.modelDonMonth),

        donationkioskmonth: _.cloneDeep(this.modelDonMonth),
        donationkioskmonthnbtransaction: _.cloneDeep(this.modelDonMonth),
        donationkioskmonthaverage: _.cloneDeep(this.modelDonMonth),
        donationkioskmonthnbdonator: _.cloneDeep(this.modelDonMonth),


        donationkioskemailtoday: _.cloneDeep(this.modelDonMonth),
        donationkioskemailmonth: _.cloneDeep(this.modelDonMonth),
        donationkioskemailyear: _.cloneDeep(this.modelDonMonth),


        //View Kiosk Donation
        donationcampaign: _.cloneDeep(this.modelDonMonth),
        donationcampaignnbtransaction: _.cloneDeep(this.modelDonMonth),
        donationcampaignday: _.cloneDeep(this.modelDonMonth),



    }
    private _AuthenticateService: AuthenticationService;

    constructor(private userService: UserService,
        private transactionService: TransactionService,
        private stripeTransactionService: StripeTransactionService,
        private organisationService: OrganisationService,
        private kpiService: KpiService,
        private _router: Router

    ) {


        this.formatter = new formatter();
        this.defineDate();
        this.modelDashboard.donationyear['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationday['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationmonth['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.nbdonator['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.nbtransaction['infoDate'] = _.cloneDeep(this.curentDate);


        this.modelDashboard.donationtotalgen['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationtotalnet['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationtotalfee['infoDate'] = _.cloneDeep(this.curentDate);


        this.modelDashboard.donationwithemail['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationwithoutemail['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationnbtotal['infoDate'] = _.cloneDeep(this.curentDate);


        this.modelDashboard.payoutwaiting['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.payoutinprogress['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.payoutfinished['infoDate'] = _.cloneDeep(this.curentDate);

        this.modelDashboard.donationkiosk['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkiosknbtransaction['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskaverage['infoDate'] = _.cloneDeep(this.curentDate);

        this.modelDashboard.donationkiosktoday['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkiosktodaynbtransaction['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkiosktodayaverage['infoDate'] = _.cloneDeep(this.curentDate);

        this.modelDashboard.donationkioskemailtoday['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskemailmonth['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskemailyear['infoDate'] = _.cloneDeep(this.curentDate);


        this.modelDashboard.donationkioskyear['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskyearnbtransaction['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskyearaverage['infoDate'] = _.cloneDeep(this.curentDate);

        this.modelDashboard.donationkioskmonth['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskmonthnbtransaction['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationkioskmonthaverage['infoDate'] = _.cloneDeep(this.curentDate);


        this.modelDashboard.donationcampaign['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationcampaignnbtransaction['infoDate'] = _.cloneDeep(this.curentDate);
        this.modelDashboard.donationcampaignday['infoDate'] = _.cloneDeep(this.curentDate);



        this._unsubscribeAll = new Subject();
        this._AuthenticateService = this.userService.getAuthenticateService();
        this._AuthenticateService.currentUserSubject.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.currentUser = res;
        });


    }


    defineDate() {

        const date = new Date();
        const dayWeek = date.getDay();
        const dayMonth = date.getDate();
        const month = date.getMonth() + 1;
        const years = date.getFullYear();
        this.curentDate.dayMonth = dayMonth;
        this.curentDate.years = years.toString();
        const days = this.formatter.days;
        const monthsShort = this.formatter.monthShort;

        for (let i = 0; i < days.length; i++) {
            if (dayWeek - 1 === i) {
                this.curentDate.dayWeek = days[i];
            }
        }

        for (let i = 0; i < monthsShort.length; i++) {
            if (month - 1 === i) {
                this.curentDate.monthShort = monthsShort[i];
            }
        }
    }

    defineDate2(oPeriode, oinfoDate) {
        var periode = _.cloneDeep(this.curentDate);
        periode.prefixdate = oinfoDate.prefixdate;
        periode.formatdate = oinfoDate.formatdate;

        if (oPeriode !== undefined && oPeriode !== null
            && oPeriode.date !== undefined
            && oPeriode.date !== null
        ) {
            const date = new Date(oPeriode.date);
            const dayWeek = date.getDay();
            const dayMonth = date.getDate();
            const month = date.getMonth() + 1;
            const years = date.getFullYear();
            periode.dayMonth = dayMonth;
            periode.years = years.toString();
            const days = this.formatter.days;
            const monthsShort = this.formatter.monthShort;

            for (let i = 0; i < days.length; i++) {
                if (dayWeek - 1 === i) {
                    periode.dayWeek = days[i];
                }
            }

            for (let i = 0; i < monthsShort.length; i++) {
                if (month - 1 === i) {
                    periode.monthShort = monthsShort[i];
                }
            }

            periode.text = this.formatter.formtDateText(periode.prefixdate, periode, periode.formatdate);
        }
        return periode;
    }

    getRouteConf() {
        let route = {
            transaction: ['/dashboard/transactions'],
            campaign: ['/dashboard/campaign', '/dashboard/campaign/campaign-list'],
            donator: ['/dashboard/donator'],
            transfer: ['/dashboard/transfert'],
            kiosk: ['/dashboard/kiosk'],
            kioskdetails: ['/dashboard/kioskdetails'],
            chart: ['/dashboard/chart'],
            kpiyear: ['/dashboard/year'],
            kpimonth: ['/dashboard/month'],
            kpitoday: ['/dashboard/today'],
        };

        return route;
    }

    getRefreshData() {
        let routeConf = this.getRouteConf();
        let currentUrl = this._router.url;

        let model = {
            getKpiTransactionYear: false,
            getKpiTransactionDay: false,
            getKpiTransactionMonth: false,

            kpinbemailtransactionall2: false,

            getKpiTransactionView: false,

            getKpiDonationView: false,

            getKpiPayoutView: false,

            getKpiKioskView: false,
            getKpiKioskViewMonth: false,
            getKpiKioskViewYear: false,
            getKpiKioskViewToday: false,

            getKpiFirstLast: true,
            getKpiCampaignYear: false,
            getKpiCampaignDay: false,
            getLastDateTransaction: true,

        }

        if (routeConf.kpiyear.indexOf(currentUrl) > -1) {
            model.getKpiTransactionView = true;
            model.getKpiTransactionYear = true;
            model.getKpiTransactionDay = true;
            model.getKpiTransactionMonth = true;
            model.kpinbemailtransactionall2 = true;
        }

        if (routeConf.kpimonth.indexOf(currentUrl) > -1) {
            model.getKpiTransactionView = true;
            model.getKpiTransactionYear = true;
            model.getKpiTransactionDay = true;
            model.getKpiTransactionMonth = true;
            model.kpinbemailtransactionall2 = true;
        }
        if (routeConf.kpitoday.indexOf(currentUrl) > -1) {
            model.getKpiTransactionView = true;
            model.getKpiTransactionYear = true;
            model.getKpiTransactionDay = true;
            model.getKpiTransactionMonth = true;
            model.kpinbemailtransactionall2 = true;
        }
        if (routeConf.transaction.indexOf(currentUrl) > -1) {
            model.getKpiTransactionView = true;
            model.getKpiTransactionYear = true;
            model.getKpiTransactionDay = true;
            model.getKpiTransactionMonth = true;
        }
        if (routeConf.campaign.indexOf(currentUrl) > -1) {
            model.getKpiCampaignYear = true;
            model.getKpiCampaignDay = true;
        }
        if (routeConf.donator.indexOf(currentUrl) > -1) {
            model.getKpiDonationView = true;
        }

        if (routeConf.transfer.indexOf(currentUrl) > -1) {
            model.getKpiPayoutView = true;

        }
        if (routeConf.kiosk.indexOf(currentUrl) > -1) {
            model.getKpiKioskView = true;
            model.getKpiKioskViewMonth = true;
            model.getKpiKioskViewYear = true;
            model.getKpiKioskViewToday = true;
        }

        if (routeConf.kioskdetails.indexOf(currentUrl) > -1) {
            model.getKpiKioskView = true;
            model.getKpiKioskViewMonth = true;
            model.getKpiKioskViewYear = true;
            model.getKpiKioskViewToday = true;
        }


        if (currentUrl.startsWith("/dashboard/kioskdetails/")
            || currentUrl.startsWith("/dashboard/kiosk")) {
            //model.getKpiTransactionYear = true;
            model.getKpiKioskView = true;
            model.getKpiKioskViewMonth = true;
            model.getKpiKioskViewYear = true;
            model.getKpiKioskViewToday = true;

        }

        if (routeConf.chart.indexOf(currentUrl) > -1) {
            model.getKpiTransactionYear = true;

            model.getKpiTransactionView = true;
            model.getKpiTransactionYear = true;
            model.getKpiTransactionDay = true;
            model.getKpiTransactionMonth = true;
            model.kpinbemailtransactionall2 = true;
        }

        return model;


    }

    formatDataDashboardAll(context, route?) {

        if (this.userService.isLoggedIn()) { }



        let model = this.getRefreshData();


        context.idOrganisation = this.currentUser.idOrg;
        context.idOrganization = this.currentUser.idOrg;



        this.setKPIDashboard(context);
        // this.getDonationAll(context.idOrganization, this.modelDashboard.donationyear, this.formatDataDashboard.bind(this, 'donationyear', this.modelDashboard.donationyear));
        //this.getDonationDay(context.idOrganization, this.modelDashboard.donationday, this.formatDataDashboard.bind(this, 'donationday', this.modelDashboard.donationday));
        // this.getDonationMonth(context.idOrganization, this.modelDashboard.donationmonth, this.formatDataDashboard.bind(this, 'donationmonth', this.modelDashboard.donationmonth));
        //this.getNbTransaction(context.idOrganization, this.modelDashboard.nbtransaction, this.formatDataDashboard.bind(this, 'donationnbtransaction', this.modelDashboard.nbtransaction));         
        if (model.getKpiTransactionYear == true)
            this.getKpiTransactionYear(context.idOrganization, this.modelDashboard, this.setDataFromTransactionYear.bind(this));
        if (model.getKpiTransactionDay == true)
            this.getKpiTransactionDay(context.idOrganization, this.modelDashboard, this.setDataFromTransactionDay.bind(this));
        if (model.getKpiTransactionMonth == true)
            this.getKpiTransactionMonth(context.idOrganization, this.modelDashboard, this.setDataFromTransactionMonth.bind(this));
        //this.kpinbemailtransactionall(context.idOrganization, this.modelDashboard, this.setDataFromNbDonatorAll.bind(this));
        if (model.kpinbemailtransactionall2 == true)
            this.kpinbemailtransactionall2(context.idOrganization, this.modelDashboard, this.setDataFromNbDonatorAll.bind(this));
        if (model.getKpiTransactionView == true)
            this.getKpiTransactionView(context.idOrganization, this.modelDashboard, this.setDataFromTransactionView.bind(this));
        if (model.getKpiDonationView == true)
            this.getKpiDonationView(context.idOrganization, this.modelDashboard, this.setDataFromDonationView.bind(this));
        if (model.getKpiPayoutView == true) {
            this.getKpiPayoutView(context.idOrganization, this.modelDashboard, this.setDataFromPayoutView.bind(this));
            this.getKpiTransactionView(context.idOrganization, this.modelDashboard, this.setDataFromTransactionView.bind(this));
        }
        if (model.getKpiKioskView == true) {
            if (context.idKiosk !== undefined && context.idKiosk !== null) {
                this.getKpiKioskView(context.idOrganization, context.idKiosk, this.modelDashboard, this.setDataFromKioskView.bind(this));
            }
        }
        if (model.getKpiKioskViewMonth == true) {
            if (context.idKiosk !== undefined && context.idKiosk !== null) {
                this.getKpiKioskViewMonth(context.idOrganization, context.idKiosk, this.modelDashboard, this.setDataFromKioskViewMonth.bind(this));
                this.kpikiosktransactionnbemailtoday(context.idOrganization, context.idKiosk, this.modelDashboard, this.setDataFromKioskViewEmail.bind(this));
            }
        }
        if (model.getKpiKioskViewYear == true) {
            if (context.idKiosk !== undefined && context.idKiosk !== null) {
                this.getKpiKioskViewYear(context.idOrganization, context.idKiosk, this.modelDashboard, this.setDataFromKioskViewYear.bind(this));
            }
        }
        if (model.getKpiKioskViewToday == true) {
            if (context.idKiosk !== undefined && context.idKiosk !== null) {
                this.getKpiKioskViewToday(context.idOrganization, context.idKiosk, this.modelDashboard, this.setDataFromKioskViewToday.bind(this));
            }
        }

        if (model.getKpiFirstLast == true)
            this.getKpiFirstLast(context.idOrganization, this.modelDashboard, this.setDataFromKioskView.bind(this));

        if (model.getKpiCampaignYear == true)
            this.getKpiCampaignYear(context.idOrganization, context.idCampaign, this.modelDashboard, this.setDataFromCampaignView.bind(this));
        if (model.getKpiCampaignDay == true)
            this.getKpiCampaignDay(context.idOrganization, context.idCampaign, this.modelDashboard, this.setDataFromCampaignDayView.bind(this));

        if (model.getLastDateTransaction == true)
            this.getLastDateTransaction(context.idOrganization);
        //this.formatDataDashboard('donationnbdonator', this.modelDashboard.nbdonator);
        //this.formatDataDashboard('donationaverage', this.modelDashboard.donationaverage);

    }

    setKPIDashboard(data) {

        data.averageDonation = this.fakeData(data.averageDonation, 'donationaverage');
        data.nbDonator = this.fakeData(data.nbDonator, 'donationnbdonator');

        // KPI 1 : Don montant
        // Day
        this.modelDashboard.donationday.type = "money";
        this.modelDashboard.donationday.decimalPlaces = 2;
        var modelDateD = _.cloneDeep(this.curentDate);
        modelDateD.formatdate = "DDddMMMAAAA";
        modelDateD.prefixdate = "";
        this.modelDashboard.donationday.infoDate.formatdate = modelDateD.formatdate;
        this.modelDashboard.donationday.infoDate.prefixdate = modelDateD.prefixdate;

        // Month
        this.modelDashboard.donationmonth.type = "money";
        this.modelDashboard.donationmonth.decimalPlaces = 2;
        var modelDateM = _.cloneDeep(this.curentDate);
        modelDateM.formatdate = "MMMAAAA";
        modelDateM.prefixdate = "";
        this.modelDashboard.donationmonth.infoDate.formatdate = modelDateM.formatdate;
        this.modelDashboard.donationmonth.infoDate.prefixdate = modelDateM.prefixdate;

        // Year 
        this.modelDashboard.donationyear.type = "money";
        this.modelDashboard.donationyear.decimalPlaces = 2;
        var modelDateM = _.cloneDeep(this.curentDate);
        modelDateM.formatdate = "AAAA";
        modelDateM.prefixdate = "Année";
        this.modelDashboard.donationyear.infoDate.formatdate = modelDateM.formatdate;
        this.modelDashboard.donationyear.infoDate.prefixdate = modelDateM.prefixdate;

        // KPI 2 : Nombre de transactions
        // Day
        this.modelDashboard.nbtransaction.type = "numeric";
        this.modelDashboard.nbtransaction.decimalPlaces = 0;
        var modelDateNb = _.cloneDeep(this.curentDate);
        modelDateNb.formatdate = "DDddMMMAAAA";
        modelDateNb.prefixdate = "";
        this.modelDashboard.nbtransaction.infoDate.formatdate = modelDateNb.formatdate;
        this.modelDashboard.nbtransaction.infoDate.prefixdate = modelDateNb.prefixdate;
        // Year
        this.modelDashboard.yearnbtransaction.type = "numeric";
        this.modelDashboard.yearnbtransaction.decimalPlaces = 0;
        var modelDateNb = _.cloneDeep(this.curentDate);
        modelDateNb.formatdate = "AAAA";
        modelDateNb.prefixdate = "Année";
        this.modelDashboard.yearnbtransaction.infoDate.formatdate = modelDateNb.formatdate;
        this.modelDashboard.yearnbtransaction.infoDate.prefixdate = modelDateNb.prefixdate;
        // Month
        this.modelDashboard.monthnbtransaction.type = "numeric";
        this.modelDashboard.monthnbtransaction.decimalPlaces = 0;
        var modelDateNb = _.cloneDeep(this.curentDate);
        modelDateNb.formatdate = "MMMAAAA";
        modelDateNb.prefixdate = "";
        this.modelDashboard.monthnbtransaction.infoDate.formatdate = modelDateNb.formatdate;
        this.modelDashboard.monthnbtransaction.infoDate.prefixdate = modelDateNb.prefixdate;

        // KPI 3 : Email donateur
        // Day
        this.modelDashboard.nbdonator.type = "numeric";
        this.modelDashboard.nbdonator.decimalPlaces = 0;
        var modelDateDo = _.cloneDeep(this.curentDate);
        modelDateDo.formatdate = "DDddMMMAAAA";
        modelDateDo.prefixdate = "";
        this.modelDashboard.nbdonator.infoDate.formatdate = modelDateDo.formatdate;
        this.modelDashboard.nbdonator.infoDate.prefixdate = modelDateDo.prefixdate;
        // Year
        this.modelDashboard.yearnbdonator.type = "numeric";
        this.modelDashboard.yearnbdonator.decimalPlaces = 0;
        var modelDateDo = _.cloneDeep(this.curentDate);
        modelDateDo.formatdate = "AAAA";
        modelDateDo.prefixdate = "Année";
        this.modelDashboard.yearnbdonator.infoDate.formatdate = modelDateDo.formatdate;
        this.modelDashboard.yearnbdonator.infoDate.prefixdate = modelDateDo.prefixdate;
        // Month
        this.modelDashboard.monthnbdonator.type = "numeric";
        this.modelDashboard.monthnbdonator.decimalPlaces = 0;
        var modelDateDo = _.cloneDeep(this.curentDate);
        modelDateDo.formatdate = "MMMAAAA";
        modelDateDo.prefixdate = "";
        this.modelDashboard.monthnbdonator.infoDate.formatdate = modelDateDo.formatdate;
        this.modelDashboard.monthnbdonator.infoDate.prefixdate = modelDateDo.prefixdate;


        // KPI 4 : Don moyen
        // Day
        this.modelDashboard.donationaverage.type = "money";
        this.modelDashboard.donationaverage.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "DDddMMMAAAA";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationaverage.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationaverage.infoDate.prefixdate = modelDateAv.prefixdate;
        // Year
        this.modelDashboard.donationyearaverage.type = "money";
        this.modelDashboard.donationyearaverage.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "AAAA";
        modelDateAv.prefixdate = "Année";
        this.modelDashboard.donationyearaverage.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationyearaverage.infoDate.prefixdate = modelDateAv.prefixdate;
        // Month
        this.modelDashboard.donationmonthaverage.type = "money";
        this.modelDashboard.donationmonthaverage.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "MMMAAAA";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationmonthaverage.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationmonthaverage.infoDate.prefixdate = modelDateAv.prefixdate;





        // KPI TRANSACTION
        this.modelDashboard.donationtotalgen.type = "money";
        this.modelDashboard.donationtotalgen.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "DDddMMMAAAA";
        modelDateAv.prefixdate = "Depuis le";
        this.modelDashboard.donationtotalgen.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationtotalgen.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationtotalfee.type = "money";
        this.modelDashboard.donationtotalfee.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.donationtotalfee.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationtotalfee.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationtotalnet.type = "money";
        this.modelDashboard.donationtotalnet.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.donationtotalnet.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationtotalnet.infoDate.prefixdate = modelDateAv.prefixdate;



        // KPI DONATION
        this.modelDashboard.donationwithemail.type = "numeric";
        this.modelDashboard.donationwithemail.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationwithemail.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationwithemail.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationwithoutemail.type = "numeric";
        this.modelDashboard.donationwithoutemail.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationwithoutemail.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationwithoutemail.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationnbtotal.type = "numeric";
        this.modelDashboard.donationnbtotal.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationnbtotal.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationnbtotal.infoDate.prefixdate = modelDateAv.prefixdate;



        // KPI PAYOUT
        this.modelDashboard.payoutinprogress.type = "money";
        this.modelDashboard.payoutinprogress.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.payoutinprogress.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.payoutinprogress.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.payoutwaiting.type = "money";
        this.modelDashboard.payoutwaiting.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.payoutwaiting.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.payoutwaiting.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.payoutfinished.type = "money";
        this.modelDashboard.payoutfinished.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.payoutfinished.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.payoutfinished.infoDate.prefixdate = modelDateAv.prefixdate;




        // KPI KIOSK
        this.modelDashboard.donationkiosk.type = "money";
        this.modelDashboard.donationkiosk.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosk.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosk.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkiosknbtransaction.type = "numeric";
        this.modelDashboard.donationkiosknbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosknbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosknbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkioskaverage.type = "money";
        this.modelDashboard.donationkioskaverage.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskaverage.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskaverage.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkiosktoday.type = "money";
        this.modelDashboard.donationkiosktoday.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosktoday.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosktoday.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkioskemailtoday.type = "numeric";
        this.modelDashboard.donationkioskemailtoday.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskemailtoday.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskemailtoday.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkiosktodaynbtransaction.type = "numeric";
        this.modelDashboard.donationkiosktodaynbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosktodaynbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosktodaynbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkiosktodayaverage.type = "numeric";
        this.modelDashboard.donationkiosktodayaverage.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosktodayaverage.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosktodayaverage.infoDate.prefixdate = modelDateAv.prefixdate;


        this.modelDashboard.donationkioskemailmonth.type = "numeric";
        this.modelDashboard.donationkioskemailmonth.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskemailmonth.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskemailmonth.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkioskemailyear.type = "numeric";
        this.modelDashboard.donationkioskemailyear.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskemailyear.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskemailyear.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkiosktodaynbtransaction.type = "numeric";
        this.modelDashboard.donationkiosktodaynbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkiosktodaynbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkiosktodaynbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;


        this.modelDashboard.donationkioskmonthnbtransaction.type = "numeric";
        this.modelDashboard.donationkioskmonthnbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskmonthnbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskmonthnbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;


        this.modelDashboard.donationkioskyearnbtransaction.type = "numeric";
        this.modelDashboard.donationkioskyearnbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskyearnbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskyearnbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationkioskyearnbtransaction.type = "numeric";
        this.modelDashboard.donationkioskyearnbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "";
        this.modelDashboard.donationkioskyearnbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationkioskyearnbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;



        // KPI CAMPAIGN
        this.modelDashboard.donationcampaign.type = "money";
        this.modelDashboard.donationcampaign.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.donationcampaign.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationcampaign.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationcampaignnbtransaction.type = "numeric";
        this.modelDashboard.donationcampaignnbtransaction.decimalPlaces = 0;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.donationcampaignnbtransaction.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationcampaignnbtransaction.infoDate.prefixdate = modelDateAv.prefixdate;

        this.modelDashboard.donationcampaignday.type = "money";
        this.modelDashboard.donationcampaignday.decimalPlaces = 2;
        var modelDateAv = _.cloneDeep(this.curentDate);
        modelDateAv.formatdate = "";
        modelDateAv.prefixdate = "-";
        this.modelDashboard.donationcampaignday.infoDate.formatdate = modelDateAv.formatdate;
        this.modelDashboard.donationcampaignday.infoDate.prefixdate = modelDateAv.prefixdate;


    }

    formatDataDashboard(sElement, oDataDash) {

        var oldValue = oDataDash.data;
        oDataDash.datato = oDataDash.data;
        oDataDash.datafrom = oldValue;

        var suffix = "€";
        if (oDataDash.data >= 1000000) {
            oDataDash.datato = oDataDash.data / 1000000;
            oDataDash.datafrom = oldValue / 1000000;
            oDataDash.suffix = "M€";
        } else if (oDataDash.data >= 100000) {
            oDataDash.datato = oDataDash.data / 1000;
            oDataDash.datafrom = oldValue / 1000;
            oDataDash.suffix = "K€";
        } else {
            oDataDash.suffix = suffix;
        }

        oDataDash.newcount = false;
        if (oDataDash.suffix !== oDataDash.suffixOld) {
            oDataDash.newcount = true;
            oDataDash.suffixOld = oDataDash.suffix;
        }
        try {
            if (oDataDash.type === "numeric") {
                oDataDash.suffix = oDataDash.suffix.slice(0, -1);
                oDataDash.suffixOld = oDataDash.suffixOld.slice(0, -1);
            }
            this.setCountUp(sElement, oDataDash.datato, oDataDash.datafrom, oDataDash);

        } catch (err) {
            console.log("error countup >>>" + err)
        }
    }

    setCountUp(sElement, sData, oldValue, oDataDash) {
        var options = {
            startVal: 0,
            decimalPlaces: 2,
            // duration: Number(5),
            useEasing: true,
            useGrouping: true,
            easingFn: null,
            separator: " ",
            decimal: ",",
            prefix: "",
            suffix: "€",
            numerals: null
        };

        if (oDataDash.decimalPlaces !== undefined) {
            options.decimalPlaces = oDataDash.decimalPlaces;
        }

        if (oDataDash.newcount == true || oDataDash.countDonation === undefined) {
            options.startVal = oDataDash.datafrom;
            options.suffix = oDataDash.suffix;
            oDataDash.countDonation = new CountUp(sElement, sData, options);
            this.startStopCount(oDataDash.countDonation);
            return true;
        }

        oDataDash.countDonation.update(sData);
        return true;
    }

    startStopCount(sObjCount) {
        if (sObjCount !== undefined && sObjCount !== null &&
            !sObjCount.error) {
            sObjCount.start();
        } else {
            // console.error(sObjCount.error);
        }
    }

    fakeData(iIndicator, sName) {
        if (sName == "donationaverage")
            return Math.floor((Math.random() * 10.56) + 5);

        //if (sName == "donationnbtransaction")
        //  return iIndicator + Math.floor((Math.random() * 10) + 5);

        if (sName == "donationnbdonator")
            return iIndicator + Math.floor((Math.random() * 10) + 1);

        return iIndicator;

        if (iIndicator >= 1000000) {
            iIndicator = iIndicator + 100000;
        } else {
            iIndicator = iIndicator + 100000;
        }

        return iIndicator;
    }

    getLastDateTransaction(id) {
        this.stripeTransactionService.lastDateTransactionStripeByOrganisation(id).subscribe(
            res => {
                if (res['transaction'].length !== 0) {
                    this.lastDateTransaction = this.formatter.formatDate(new Date(res['transaction'][0].transaction));
                }
            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getNbTransactionAll(id, oDataDash, next) {
        this.transactionService.getNbDonorByOrganisation(id).subscribe(
            res => {
                oDataDash.data = res['donors'].length;
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getNbTransaction(id, oDataDash, next) {
        this.transactionService.getNbTransactionPerDayByOrganisation(id).subscribe(
            res => {
                oDataDash.data = 10;
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getNbDonatorAll(id, oDataDash, next) {
        this.transactionService.getNbDonorByOrganisation(id).subscribe(
            res => {
                oDataDash.data = res['donors'].length;
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getNbDonator(id, oDataDash, next) {
        this.transactionService.getNbDonorByOrganisation(id).subscribe(
            res => {
                oDataDash.data = res['donors'].length;
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getDonationAll(id, oDataDash, next) {
        this.stripeTransactionService.sumTransactionStripeByOrganisation(id).subscribe(
            res => {
                if (res['transactions'].length !== 0) {
                    oDataDash.data = res['transactions'][0].total;
                    oDataDash.totalDonation.data = res['transactions'][0].total;
                    oDataDash.totalTransaction.data = res['transactions'][0].totalTransaction;
                    oDataDash.averageTransaction.data = res['transactions'][0].average.toFixed(2);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getDonationMonth(id, oDataDash, next) {
        this.stripeTransactionService.sumTransactionStripePerMonthByOrganisation(id).subscribe(
            res => {
                if (res['transactions'].length !== 0) {
                    oDataDash.data = res['transactions'][0].total;
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getDonationDay(id, oDataDash, next) {
        this.stripeTransactionService.sumTransactionStripePerDayByOrganisation(id).subscribe(
            res => {
                if (res['transactions'].length !== 0) {
                    oDataDash.data = res['transactions'][0].total;
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }

    setDataFromTransactionYear() {
        this.formatDataDashboard('donationyear', this.modelDashboard.donationyear);
        this.formatDataDashboard('donationyearaverage', this.modelDashboard.donationyearaverage);
        this.formatDataDashboard('donationyearnbtransaction', this.modelDashboard.yearnbtransaction);
    }

    setDataFromTransactionDay() {
        this.formatDataDashboard('donationday', this.modelDashboard.donationday);
        this.formatDataDashboard('donationaverage', this.modelDashboard.donationaverage);
        this.formatDataDashboard('donationnbtransaction', this.modelDashboard.nbtransaction);
    }

    setDataFromTransactionView() {
        this.formatDataDashboard('donationtotalgen', this.modelDashboard.donationtotalgen);
        this.formatDataDashboard('donationtotalnet', this.modelDashboard.donationtotalnet);
        this.formatDataDashboard('donationtotalfee', this.modelDashboard.donationtotalfee);
    }

    setDataFromDonationView() {
        this.formatDataDashboard('donationwithemail', this.modelDashboard.donationwithemail);
        this.formatDataDashboard('donationwithoutemail', this.modelDashboard.donationwithoutemail);
        this.formatDataDashboard('donationnbtotal', this.modelDashboard.donationnbtotal);
    }

    setDataFromPayoutView() {
        this.formatDataDashboard('payoutwaiting', this.modelDashboard.payoutwaiting);
        this.formatDataDashboard('payoutinprogress', this.modelDashboard.payoutinprogress);
        this.formatDataDashboard('payoutfinished', this.modelDashboard.payoutfinished);
    }

    setDataFromKioskView() {
        this.formatDataDashboard('donationkiosk', this.modelDashboard.donationkiosk);
        this.formatDataDashboard('donationkiosknbtransaction', this.modelDashboard.donationkiosknbtransaction);
        this.formatDataDashboard('donationkioskaverage', this.modelDashboard.donationkioskaverage);
    }


    setDataFromKioskViewToday() {
        this.formatDataDashboard('donationkiosktoday', this.modelDashboard.donationkiosktoday);
        this.formatDataDashboard('donationkiosktodaynbtransaction', this.modelDashboard.donationkiosktodaynbtransaction);
        this.formatDataDashboard('donationkiosktodayaverage', this.modelDashboard.donationkiosktodayaverage);
    }

    setDataFromKioskViewYear() {
        this.formatDataDashboard('donationkioskyear', this.modelDashboard.donationkioskyear);
        this.formatDataDashboard('donationkioskyearnbtransaction', this.modelDashboard.donationkioskyearnbtransaction);
        this.formatDataDashboard('donationkioskyearaverage', this.modelDashboard.donationkioskyearaverage);
    }
    setDataFromKioskViewMonth() {
        this.formatDataDashboard('donationkioskmonth', this.modelDashboard.donationkioskmonth);
        this.formatDataDashboard('donationkioskmonthnbtransaction', this.modelDashboard.donationkioskmonthnbtransaction);
        this.formatDataDashboard('donationkioskmonthaverage', this.modelDashboard.donationkioskmonthaverage);
    }

    setDataFromKioskViewEmail() {
        this.formatDataDashboard('donationkioskemailtoday', this.modelDashboard.donationkioskemailtoday);
        this.formatDataDashboard('donationkioskemailmonth', this.modelDashboard.donationkioskemailmonth);
        this.formatDataDashboard('donationkioskemailyear', this.modelDashboard.donationkioskemailyear);
    }

    setDataFromNbDonatorAll() {
        this.formatDataDashboard('donationyearnbdonator', this.modelDashboard.yearnbdonator);

        this.formatDataDashboard('donationnbdonator', this.modelDashboard.nbdonator);
        this.formatDataDashboard('donationmonthnbdonator', this.modelDashboard.monthnbdonator);
    }

    setDataFromTransactionMonth() {
        this.formatDataDashboard('donationmonth', this.modelDashboard.donationmonth);
        this.formatDataDashboard('donationmonthaverage', this.modelDashboard.donationmonthaverage);
        this.formatDataDashboard('donationmonthnbtransaction', this.modelDashboard.monthnbtransaction);
    }

    setDataFromCampaignView() {
        this.formatDataDashboard('donationcampaign', this.modelDashboard.donationcampaign);
        this.formatDataDashboard('donationcampaignnbtransaction', this.modelDashboard.donationcampaignnbtransaction);
    }

    setDataFromCampaignDayView() {
        this.formatDataDashboard('donationcampaignday', this.modelDashboard.donationcampaignday);
    }





    getKpiTransactionYear(id, oDataDash, next) {
        this.kpiService.kpidonationsumtransaction(id).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationyear.data = res['data'][0].total;
                    oDataDash.donationyearaverage.data = res['data'][0].average;
                    oDataDash.yearnbtransaction.data = res['data'][0].totalTransaction;
                } else {
                    oDataDash.donationyear.data = 0;
                    oDataDash.donationyearaverage.data = 0;
                    oDataDash.yearnbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationyear.infoDate = this.defineDate2(res['periode'], oDataDash.donationyear.infoDate);
                    oDataDash.donationyearaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationyearaverage.infoDate);
                    oDataDash.yearnbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.yearnbtransaction.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiCampaignYear(id, idCampaign, oDataDash, next) {
        // this.kpiService.kpidonationcampaignsumtransaction(id, idCampaign).subscribe(
        this.kpiService.kpidonationcampaignsumtransactionall(id, idCampaign).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationcampaign.data = res['data'][0].total;
                    oDataDash.donationcampaignnbtransaction.data = res['data'][0].totalTransaction;
                } else {
                    oDataDash.donationcampaign.data = 0;
                    oDataDash.donationcampaignnbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationcampaign.infoDate = this.defineDate2(res['periode'], oDataDash.donationcampaign.infoDate);
                    oDataDash.donationcampaignnbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.donationcampaignnbtransaction.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiCampaignDay(id, idCampaign, oDataDash, next) {
        this.kpiService.kpidonationcampaignsumdaytransaction(id, idCampaign).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationcampaignday.data = res['data'][0].total;
                } else {
                    oDataDash.donationcampaignday.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationcampaignday.infoDate = this.defineDate2(res['periode'], oDataDash.donationcampaignday.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();
            },
            err => {
                this.errorServer = true;
            }
        );
    }



    getKpiTransactionDay(id, oDataDash, next) {
        this.kpiService.kpidonationsumtransactionday(id).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationday.data = res['data'][0].total;
                    oDataDash.donationaverage.data = res['data'][0].average;
                    oDataDash.nbtransaction.data = res['data'][0].totalTransaction;
                } else {
                    oDataDash.donationday.data = 0;
                    oDataDash.donationaverage.data = 0;
                    oDataDash.nbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationday.infoDate = this.defineDate2(res['periode'], oDataDash.donationday.infoDate);
                    oDataDash.donationaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationaverage.infoDate);
                    oDataDash.nbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.nbtransaction.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getKpiTransactionMonth(id, oDataDash, next) {
        this.kpiService.kpidonationsumtransactionmonth(id).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationmonth.data = res['data'][0].total;
                    oDataDash.donationmonthaverage.data = res['data'][0].average;
                    oDataDash.monthnbtransaction.data = res['data'][0].totalTransaction;
                } else {
                    oDataDash.donationmonth.data = 0;
                    oDataDash.donationmonthaverage.data = 0;
                    oDataDash.monthnbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationmonth.infoDate = this.defineDate2(res['periode'], oDataDash.donationmonth.infoDate);
                    oDataDash.donationmonthaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationmonthaverage.infoDate);
                    oDataDash.monthnbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.monthnbtransaction.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiTransactionView(id, oDataDash, next) {
        // this.kpiService.kpidonationsumtransaction(id).subscribe(
        this.kpiService.kpidonationsumtransactionAll(id).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationtotalgen.data = res['data'][0].total;
                    oDataDash.donationtotalnet.data = res['data'][0].totalNet;
                    oDataDash.donationtotalfee.data = res['data'][0].totalFee;
                } else {
                    oDataDash.donationtotalgen.data = 0;
                    oDataDash.donationtotalnet.data = 0;
                    oDataDash.donationtotalfee.data = 0;
                }
                // if (res['periode'].length !== 0) {
                //     oDataDash.donationtotalgen.infoDate = this.defineDate2(res['periode'], oDataDash.donationtotalgen.infoDate);
                //     oDataDash.donationtotalnet.infoDate = this.defineDate2(res['periode'], oDataDash.donationtotalnet.infoDate);
                //     oDataDash.donationtotalfee.infoDate = this.defineDate2(res['periode'], oDataDash.donationtotalfee.infoDate);
                // }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiDonationView(id, oDataDash, next) {
        this.kpiService.kpinbemail(id).subscribe(
            res => {
                if (res['data'].withEmail !== undefined) {
                    oDataDash.donationwithemail.data = res['data'].withEmail;
                }
                if (res['data'].withoutEmail !== undefined) {
                    oDataDash.donationwithoutemail.data = res['data'].withoutEmail;
                }
                if (res['data'].total !== undefined) {
                    oDataDash.donationnbtotal.data = res['data'].total;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.donationwithemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithemail.infoDate);
                    oDataDash.donationwithoutemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithoutemail.infoDate);
                    oDataDash.donationnbtotal.infoDate = this.defineDate2(res['periode'], oDataDash.donationnbtotal.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiPayoutView(id, oDataDash, next) {
        this.kpiService.kpipayouttransactionall(id).subscribe(
            res => {
                if (res['data'] !== undefined && res['data'] !== null) {
                    if (res['data'].pending !== undefined && res['data'].pending !== null)
                        oDataDash.payoutwaiting.data = res['data'].pending;
                    if (res['data'].in_transit !== undefined && res['data'].in_transit !== null)
                        oDataDash.payoutinprogress.data = res['data'].in_transit;
                    if (res['data'].paid !== undefined && res['data'].paid !== null)
                        oDataDash.payoutfinished.data = res['data'].paid;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.payoutwaiting.infoDate = this.defineDate2(res['periode'], oDataDash.payoutwaiting.infoDate);
                    oDataDash.payoutinprogress.infoDate = this.defineDate2(res['periode'], oDataDash.payoutinprogress.infoDate);
                    oDataDash.payoutfinished.infoDate = this.defineDate2(res['periode'], oDataDash.payoutfinished.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiKioskView(id, idKiosk, oDataDash, next) {
        this.kpiService.kpikiosktransactionall(id, idKiosk).subscribe(
            res => {
                if (res['data'] !== undefined && res['data'] !== null && Array.isArray(res['data']) && res['data'].length > 0) {
                    var data = res['data'][0];
                    if (data.total !== undefined && data.total !== null)
                        oDataDash.donationkiosk.data = data.total;
                    else
                        oDataDash.donationkiosk.data = 0;

                    if (data.average !== undefined && data.average !== null)
                        oDataDash.donationkioskaverage.data = data.average;
                    else
                        oDataDash.donationkioskaverage.data = 0;

                    if (data.totalTransaction !== undefined && data.totalTransaction !== null)
                        oDataDash.donationkiosknbtransaction.data = data.totalTransaction;
                    else
                        oDataDash.donationkiosknbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationkiosk.infoDate = this.defineDate2(res['periode'], oDataDash.donationkiosk.infoDate);
                    oDataDash.donationkiosknbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.donationkiosknbtransaction.infoDate);
                    oDataDash.donationkioskaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskaverage.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    getKpiKioskViewToday(id, idKiosk, oDataDash, next) {

        this.kpiService.kpikiosktransactiontoday(id, idKiosk).subscribe(
            res => {
                if (res['data'] !== undefined && res['data'] !== null && Array.isArray(res['data']) && res['data'].length > 0) {
                    var data = res['data'][0];
                    if (data.total !== undefined && data.total !== null)
                        oDataDash.donationkiosktoday.data = data.total;
                    else
                        oDataDash.donationkiosktoday.data = 0;

                    if (data.average !== undefined && data.average !== null)
                        oDataDash.donationkiosktodayaverage.data = data.average;
                    else
                        oDataDash.donationkiosktodayaverage.data = 0;

                    if (data.totalTransaction !== undefined && data.totalTransaction !== null)
                        oDataDash.donationkiosktodaynbtransaction.data = data.totalTransaction;
                    else
                        oDataDash.donationkiosktodaynbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationkiosktoday.infoDate = this.defineDate2(res['periode'], oDataDash.donationkiosktoday.infoDate);
                    oDataDash.donationkiosktodaynbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.donationkiosktodaynbtransaction.infoDate);
                    oDataDash.donationkiosktodayaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationkiosktodayaverage.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }
    getKpiKioskViewMonth(id, idKiosk, oDataDash, next) {
        this.kpiService.kpikiosktransactionmonth(id, idKiosk).subscribe(
            res => {
                if (res['data'] !== undefined && res['data'] !== null && Array.isArray(res['data']) && res['data'].length > 0) {
                    var data = res['data'][0];
                    if (data.total !== undefined && data.total !== null)
                        oDataDash.donationkioskmonth.data = data.total;
                    else
                        oDataDash.donationkioskmonth.data = 0;
                    if (data.average !== undefined && data.average !== null)
                        oDataDash.donationkioskmonthaverage.data = data.average;
                    else
                        oDataDash.donationkioskmonthaverage.data = 0;

                    if (data.totalTransaction !== undefined && data.totalTransaction !== null)
                        oDataDash.donationkioskmonthnbtransaction.data = data.totalTransaction;
                    else
                        oDataDash.donationkioskmonthnbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationkioskmonth.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskmonth.infoDate);
                    oDataDash.donationkioskmonthnbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskmonthnbtransaction.infoDate);
                    oDataDash.donationkioskmonthaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskmonthaverage.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }
    getKpiKioskViewYear(id, idKiosk, oDataDash, next) {
        this.kpiService.kpikiosktransactionyear(id, idKiosk).subscribe(
            res => {
                if (res['data'] !== undefined && res['data'] !== null && Array.isArray(res['data']) && res['data'].length > 0) {
                    var data = res['data'][0];
                    if (data.total !== undefined && data.total !== null)
                        oDataDash.donationkioskyear.data = data.total;
                    else
                        oDataDash.donationkioskyear.data = 0;

                    if (data.average !== undefined && data.average !== null)
                        oDataDash.donationkioskyearaverage.data = data.average;
                    else
                        oDataDash.donationkioskyearaverage.data = 0;


                    if (data.totalTransaction !== undefined && data.totalTransaction !== null)
                        oDataDash.donationkioskyearnbtransaction.data = data.totalTransaction;
                    else
                        oDataDash.donationkioskyearnbtransaction.data = 0;
                }
                if (res['periode'].length !== 0) {
                    oDataDash.donationkioskyear.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskyear.infoDate);
                    oDataDash.donationkioskyearnbtransaction.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskyearnbtransaction.infoDate);
                    oDataDash.donationkioskyearaverage.infoDate = this.defineDate2(res['periode'], oDataDash.donationkioskyearaverage.infoDate);
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    getKpiFirstLast(id, oDataDash, next) {
        this.kpiService.kpifirstlastdate(id).subscribe(
            res => {
                if (res['periode'] !== undefined && res['periode'] !== null) {
                    var data = res['periode'];
                    this.updateInfoDate(data, oDataDash);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    updateInfoDate(oPeriode, oDataDash) {

        var periodeCampaign = oPeriode.Campaign.first;
        var periodeTransaction = oPeriode.Transaction.first;
        var periode = oPeriode;


        oDataDash.yearnbdonator.infoDate = this.defineDate2(periode, oDataDash.yearnbdonator.infoDate);
        oDataDash.monthnbdonator.infoDate = this.defineDate2(periode, oDataDash.monthnbdonator.infoDate);
        oDataDash.nbdonator.infoDate = this.defineDate2(periode, oDataDash.nbdonator.infoDate);

        oDataDash.donationmonth.infoDate = this.defineDate2(periode, oDataDash.donationmonth.infoDate);
        oDataDash.donationmonthaverage.infoDate = this.defineDate2(periode, oDataDash.donationmonthaverage.infoDate);
        oDataDash.monthnbtransaction.infoDate = this.defineDate2(periode, oDataDash.monthnbtransaction.infoDate);

        oDataDash.donationwithemail.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationwithemail.infoDate);
        oDataDash.donationwithoutemail.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationwithoutemail.infoDate);
        oDataDash.donationnbtotal.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationnbtotal.infoDate);


        oDataDash.donationkiosk.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationkiosk.infoDate);
        oDataDash.donationkiosknbtransaction.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationkiosknbtransaction.infoDate);
        oDataDash.donationkioskaverage.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationkioskaverage.infoDate);

        oDataDash.payoutwaiting.infoDate = this.defineDate2(periodeTransaction, oDataDash.payoutwaiting.infoDate);
        oDataDash.payoutinprogress.infoDate = this.defineDate2(periodeTransaction, oDataDash.payoutinprogress.infoDate);
        oDataDash.payoutfinished.infoDate = this.defineDate2(periodeTransaction, oDataDash.payoutfinished.infoDate);

        oDataDash.donationtotalgen.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationtotalgen.infoDate);
        oDataDash.donationtotalnet.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationtotalnet.infoDate);
        oDataDash.donationtotalfee.infoDate = this.defineDate2(periodeCampaign, oDataDash.donationtotalfee.infoDate);



    }


    kpidonationsumtransactionmonth(id, oDataDash, next) {
        this.kpiService.kpidonationsumtransactionmonth(id).subscribe(
            res => {
                if (res['data'].length !== 0) {
                    oDataDash.donationday.data = res['data'][0].total;
                    oDataDash.donationaverage.data = res['data'][0].average;
                    oDataDash.nbtransaction.data = res['data'][0].totalTransaction;
                } else {
                    oDataDash.donationday.data = 0;
                    oDataDash.donationaverage.data = 0;
                    oDataDash.nbtransaction.data = 0;
                }
                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    kpinbemailtransactionall(id, oDataDash, next) {
        this.kpiService.kpinbemailtransactionall(id).subscribe(
            res => {
                if (res['data']['year'].length !== 0) {
                    oDataDash.yearnbdonator.data = res['data']['year'][0].totalTransaction;
                } else {
                    oDataDash.yearnbdonator.data = 0;
                }
                if (res['data']['month'].length !== 0) {
                    oDataDash.monthnbdonator.data = res['data']['month'][0].totalTransaction;
                } else {
                    oDataDash.monthnbdonator.data = 0;
                }
                if (res['data']['today'].length !== 0) {
                    oDataDash.nbdonator.data = res['data']['today'][0].totalTransaction;
                } else {
                    oDataDash.nbdonator.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.yearnbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.yearnbdonator.infoDate);
                    oDataDash.monthnbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.monthnbdonator.infoDate);
                    oDataDash.nbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.nbdonator.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    kpinbemailtransactionall2(id, oDataDash, next) {
        this.kpiService.kpinbemailtransactionall2(id).subscribe(
            res => {
                if (res['data']['year'].length !== 0) {
                    oDataDash.yearnbdonator.data = res['data']['year'][0].totalTransaction;
                } else {
                    oDataDash.yearnbdonator.data = 0;
                }
                if (res['data']['month'].length !== 0) {
                    oDataDash.monthnbdonator.data = res['data']['month'][0].totalTransaction;
                } else {
                    oDataDash.monthnbdonator.data = 0;
                }
                if (res['data']['today'].length !== 0) {
                    oDataDash.nbdonator.data = res['data']['today'][0].totalTransaction;
                } else {
                    oDataDash.nbdonator.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.yearnbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.yearnbdonator.infoDate);
                    oDataDash.monthnbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.monthnbdonator.infoDate);
                    oDataDash.nbdonator.infoDate = this.defineDate2(res['periode'], oDataDash.nbdonator.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }



    kpinbemail(id, oDataDash, next) {
        this.kpiService.kpinbemail(id).subscribe(
            res => {
                if (res['data'].withEmail !== undefined) {
                    oDataDash.donationwithemail.data = res['data'].withEmail;
                } else {
                    oDataDash.donationwithemail.data = 0;
                }
                if (res['data'].withoutEmail !== undefined) {
                    oDataDash.donationwithoutemail.data = res['data'].withoutEmail;
                } else {
                    oDataDash.donationwithoutemail.data = 0;
                }
                if (res['data'].total !== undefined) {
                    oDataDash.donationnbtotal.data = res['data'].total;
                } else {
                    oDataDash.donationnbtotal.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.donationwithemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithemail.infoDate);
                    oDataDash.donationwithoutemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithoutemail.infoDate);
                    oDataDash.donationnbtotal.infoDate = this.defineDate2(res['periode'], oDataDash.donationnbtotal.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }


    kpikiosktransactionnbemailtoday(id, idKiosk, oDataDash, next) {

        this.kpiService.kpikiosktransactionnbemailtoday(id, idKiosk).subscribe(
            res => {
                if (res['data'].withEmail !== undefined) {
                    oDataDash.donationwithemail.data = res['data'].withEmail;
                } else {
                    oDataDash.donationwithemail.data = 0;
                }
                if (res['data'].withoutEmail !== undefined) {
                    oDataDash.donationwithoutemail.data = res['data'].withoutEmail;
                } else {
                    oDataDash.donationwithoutemail.data = 0;
                }
                if (res['data'].total !== undefined) {
                    oDataDash.donationnbtotal.data = res['data'].total;
                } else {
                    oDataDash.donationnbtotal.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.donationwithemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithemail.infoDate);
                    oDataDash.donationwithoutemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithoutemail.infoDate);
                    oDataDash.donationnbtotal.infoDate = this.defineDate2(res['periode'], oDataDash.donationnbtotal.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    kpikiosktransactionnbemailmonth(id, idKiosk, oDataDash, next) {
        this.kpiService.kpikiosktransactionnbemailmonth(id, idKiosk).subscribe(
            res => {
                if (res['data'].withEmail !== undefined) {
                    oDataDash.donationwithemail.data = res['data'].withEmail;
                } else {
                    oDataDash.donationwithemail.data = 0;
                }
                if (res['data'].withoutEmail !== undefined) {
                    oDataDash.donationwithoutemail.data = res['data'].withoutEmail;
                } else {
                    oDataDash.donationwithoutemail.data = 0;
                }
                if (res['data'].total !== undefined) {
                    oDataDash.donationnbtotal.data = res['data'].total;
                } else {
                    oDataDash.donationnbtotal.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.donationwithemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithemail.infoDate);
                    oDataDash.donationwithoutemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithoutemail.infoDate);
                    oDataDash.donationnbtotal.infoDate = this.defineDate2(res['periode'], oDataDash.donationnbtotal.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }

    kpikiosktransactionnbemailyear(id, idKiosk, oDataDash, next) {
        this.kpiService.kpikiosktransactionnbemailyear(id, idKiosk).subscribe(
            res => {
                if (res['data'].withEmail !== undefined) {
                    oDataDash.donationwithemail.data = res['data'].withEmail;
                } else {
                    oDataDash.donationwithemail.data = 0;
                }
                if (res['data'].withoutEmail !== undefined) {
                    oDataDash.donationwithoutemail.data = res['data'].withoutEmail;
                } else {
                    oDataDash.donationwithoutemail.data = 0;
                }
                if (res['data'].total !== undefined) {
                    oDataDash.donationnbtotal.data = res['data'].total;
                } else {
                    oDataDash.donationnbtotal.data = 0;
                }

                if (res['periode'] !== undefined) {
                    oDataDash.donationwithemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithemail.infoDate);
                    oDataDash.donationwithoutemail.infoDate = this.defineDate2(res['periode'], oDataDash.donationwithoutemail.infoDate);
                    oDataDash.donationnbtotal.infoDate = this.defineDate2(res['periode'], oDataDash.donationnbtotal.infoDate);
                }

                if (next !== undefined && next !== null && next.call !== undefined)
                    next.call();

            },
            err => {
                this.errorServer = true;
            }
        );
    }



}