import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { InvoiceFakeData } from './invoice.data';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { distinctUntilChanged } from 'rxjs/operators';
import { CampaignListService } from './campaign/campaign-list/campaign-list.service';

import { DatatablesService as donateurService } from 'app/main/dashboard/donator/datatables.service';
import { DatatablesService as donateurTransactionService } from 'app/main/dashboard/donator/donator-transaction/datatables.service';
import { DatatablesService as payout_DatatablesService } from 'app/main/dashboard/payout/datatables.service';
import { DatatablesService as kiosk_DatatablesService } from 'app/main/dashboard/kiosk/datatables.service';
import { DatatablesService as kioskDetails_DatatablesService } from 'app/main/dashboard/kiosk/kiosk-details/datatables.service';
import { KioskStatisticsService as kioskChart_DatatablesService } from 'app/main/dashboard/kiosk/kiosk-chart/kiosk-statistics.service';
import { CardStatisticsService } from 'app/main/dashboard/card-statistics.service';

@Injectable({
  providedIn: 'root'
})
export class DatatablesService implements Resolve<any> {
  rows: any;

  idOrg: string;
  onDatatablessChanged: BehaviorSubject<any>;
  public currentTransaction: Observable<any>;

  public currentUser: User;
  public compoTransaction: any;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private _authenticationService: AuthenticationService,
    private _campaignService: CampaignListService,
    private _donatorService: donateurService,
    private _donatorTransactionService: donateurTransactionService,
    private _payoutTransactionService: payout_DatatablesService,
    private _kiosk: kiosk_DatatablesService,
    private _kioskDetails: kiosk_DatatablesService,
    private _kioskChart: kioskChart_DatatablesService,
    private _reportChart: CardStatisticsService,

  ) {
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentTransaction = this.onDatatablessChanged.asObservable().pipe(distinctUntilChanged());


  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  getCampaignRows() {
    this._campaignService.refreshData();
  }

  getDonatorRows() {
    this._donatorService.refreshData();
  }
  getDonatorTransactionRows() {
    this._donatorTransactionService.refreshData();
  }

  getPayoutList() {
    this._payoutTransactionService.refreshData();
  }

  getKioskList() {
    this._kiosk.refreshData();
  }
  getKioskDetailsList() {
    this._kioskDetails.refreshData();
  }
  getKioskChartList() {
    this._kioskChart.refreshData();
  }

  getReportChartList() {
    this._reportChart.refreshData();
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser !== undefined && this.currentUser !== null && this.currentUser.idOrg !== undefined && this.currentUser.idOrg !== null) {
      return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/api/stripe/transaction/all/organization/${this.currentUser.idOrg}`).subscribe((response: any) => {
          //this.rows = InvoiceFakeData.invoices;
          this.rows = response['transactions'];
          this.rows = this.formatTransactions(this.rows);
          this.onDatatablessChanged.next(this.rows);

          resolve(this.rows);
        }, reject);
      });
    };
  }
  sortByDateDescending(field, b, a) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  }

  sortByDateAscending(field, b, a) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  }

  formatTransactions(tRows) {

    if (Array.isArray(tRows)) {

      tRows = tRows.sort(this.sortByDateDescending.bind(this, 'dateLocal'));

      tRows.forEach(element => {
        let data;
        element.campaigntitle = "";
        element.campaignId = "";
        element.icon = "";
        element.emailFormatted = "non renseigné";

        if (element.payment_method_details !== undefined && element.payment_method_details !== null) {
          if (element.payment_method_details.card !== undefined && element.payment_method_details.card !== null) {
            data = element.payment_method_details.card;
          }
          if (element.payment_method_details.card_present !== undefined && element.payment_method_details.card_present !== null) {
            data = element.payment_method_details.card_present;
          }
        }
        element.payment_method_details.info = data;
        if (element.email !== undefined && element.email !== null) {
          if (element.email !== "" && element.email !== "null") {
            element.emailFormatted = element.email;
          }

        }

        if (Array.isArray(element.campaign) && element.campaign.length > 0) {
          if (element.campaign[0].title !== undefined && element.campaign[0].title !== null) {
            element.campaigntitle = element.campaign[0].title;
            element.campaignId = element.campaign[0].idCampaign;
          }
        }
        if (Array.isArray(element.transacCampaing) && element.transacCampaing.length > 0) {
          if (element.transacCampaing[0].title !== undefined && element.transacCampaing[0].title !== null) {
            element.campaigntitle = element.transacCampaing[0].title;
            element.campaignId = element.transacCampaing[0].idCampaign;
          }


          if (element.transacCampaing[0].icon !== undefined && element.transacCampaing[0].icon !== null) {
            element.icon = element.transacCampaing[0].icon;
          }
        }




      });

    }

    return tRows;
  }


}
