import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  getAllCampaign() {
    return this.http.get(environment.apiBaseUrl + '/campaign/all');
  }

  getCampaignOrganization(id, filterState: string) {
    // return this.http.get(environment.apiBaseUrl + '/campaign/organization/' + id);
    var params = { "filterState": filterState };
    var param = {
      params: params
    }
    return this.http.get(environment.apiBaseUrl + '/campaign/organization/bisV2/' + id, param);
  }

  getDeviceOrganization(id, filterState: string) {
    // return this.http.get(environment.apiBaseUrl + '/campaign/organization/' + id);
    var params = { "filterState": filterState };
    var param = {
      params: params
    }
    return this.http.get(environment.apiBaseUrl + '/campaign/devices/' + id, param);
  }

  addCampaign(campaign) {
    return this.http.post(environment.apiBaseUrl + '/campaign/create', campaign);
  }

  update(campaign) {
    return this.http.post(environment.apiBaseUrl + '/campaign/update', campaign);
  }
  updatev2(campaign) {
    return this.http.post(environment.apiBaseUrl + '/campaignv2/update', campaign);
  }
  getCampaignById(id) {
    return this.http.get(environment.apiBaseUrl + '/campaign/' + id);
  }

  getCampaignByIdCampaign(id) {
    return this.http.get(environment.apiBaseUrl + '/campaign/idcampaign/' + id);
  }

  getCampaignAndTransactionAggregate(id) {
    return this.http.get(environment.apiBaseUrl + '/campaign/transaction/' + id);
  }

  updateStatus(campaign) {
    return this.http.post(environment.apiBaseUrl + '/campaign/status/update', campaign);
  }

  updateArchive(campaign) {
    return this.http.post(environment.apiBaseUrl + '/campaign/archive/update', campaign);
  }

  donCampaignOrganization(id) {
    return this.http.get(environment.apiBaseUrl + '/don/organisation/campaign/' + id);
  }


  setTemplateTileAmount(id, data) {
    return this.http.post(environment.apiBaseUrl + '/campaign/updatetile/' + id, data);
  }

}
