// export const environment = {
//   production: false,
//   hmr: false, 
//   apiUrl: 'https://localhost:444',
//   apiBaseUrl: 'https://localhost:444/api',
//   apiBaseUrl2: 'https://localhost:444/apikpi',
//   apiBaseUrl3: 'http://fileservice.e6kdonation.com:4076/files'

//   // apiUrl: 'https://app.v2.e6kdonation.com',
//   // apiBaseUrl: '/api',
//   // apiBaseUrl2: '/apikpi',
//   // apiBaseUrl3: 'http://fileservice.e6kdonation.com:4076/files'
// };
// export const environment = {
//   production: true,
//   hmr: false,
//   apiUrl: 'https://app.e6kdonation.com',
//   apiBaseUrl: 'https://app.e6kdonation.com/api',
//   apiBaseUrl2: 'https://app.e6kdonation.com/apikpi',
//   apiBaseUrl3: 'http://fileservice.e6kdonation.com:4076/files',
//   apiBaseUrl4 : 'http://46.101.211.102:3010/api',
// };

export const environment = {
  production: true,
  hmr: false,
  //apiUrl: 'https://app.v2.dev.essika.fr',
  // apiUrl: 'https://appsta.e6kdonation.com',
  apiUrl: 'https://app.v2.e6kdonation.com',
  apiBaseUrl: '/api',
  apiBaseUrl2: '/apikpi',
  apiBaseUrl3: '/files',
  apiBaseUrl4: '/api2',
};

// export const environment = {
//   production: true,
//   hmr: false,
//   apiUrl: 'https://app.v2.e6kdonation.com',
//   apiBaseUrl: '/api',
//   apiBaseUrl2: '/apikpi',
//   apiBaseUrl3: 'https://fileservice.e6kdonation.com:4076/files',
//   apiBaseUrl4 : '/api2',
// };