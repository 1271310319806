export class Campaign {
  _id: String;
  idCampaign: String;
  icon: String;
  title: String;
  description: String;
  created: string;
  actif: Boolean = false;
  collected: any;
  editable: Boolean;
  idOrganization: String;
  idDeviceList: string[];
  idKioskList: string[];
  status: String;
  iconpath: String;
  paramReceiptfiscal: [{
    available: Boolean,
    periode: {
      dateStart: Date;
      dateEnd: Date;
      yearFiscal: String;
    },
  }];

  receiptfiscal: {
    available: Boolean;
    periode: {
      dateStart: Date;
      dateEnd: Date;
      yearFiscal: String;
    }
  };

  paiementInfo: {
    _id: String;
    rib: String;
    idOrganization: String;
    AccountId: String;
    LocationId: String;
  };

  tileAmount: {
    num05: Number;
    num10: Number;
    num20: Number;
    num50: Number;
    num100: Number;
    numAutre: String;
    numMin: Number;

  };
  activeTileAmountCampaign: Boolean = false;


}
